import { dateToLocal, dateToLocalDate } from '../helpers';
import { ClientCreditCard, PaymentsList, ReportTokenResponse, SendContactBillingRequest, SendContactBillingResponse, Statement, StatementsList, SubmitPayment, SubmitPaymentResponse, SubmitSaveCard } from '../models/Payments';
import { LayoutStore } from '../stores/layoutStore';
import { PaymentStore } from '../stores/paymentStore';
import { http } from './http';
import { NoticationTypes } from '../models/enums';

let paymentStore: PaymentStore;
let layoutStore: LayoutStore;

const fetchStatementList = async (store: PaymentStore, loStore: LayoutStore) => {
    paymentStore = store;
    layoutStore = loStore;

    store.setShowPaymentSpinner(true);
    store.clearSelectedStatementRowNum();

    let statementsList: StatementsList = {
        success: false,
        friendlyMessage: '',
        statements: []
    };

    const response = await http<undefined, StatementsList>({
        path: `payment/getBilling`
    });
    if (response.ok && response.parsedBody) {
        if (response.parsedBody.success) {
            response.parsedBody.statements.forEach(m => {
                m.serviceDate = dateToLocal(m.serviceDate);
                if (m.initialInvoicedDate) {
                    m.initialInvoicedDate = dateToLocal(m.initialInvoicedDate);
                }
            })
            statementsList = response.parsedBody;
        }
        else {
            layoutStore.setShowNotification(true, NoticationTypes.error, response.parsedBody.friendlyMessage);
        }
    } else {
        layoutStore.setShowNotification(true, NoticationTypes.error, `API Error: ${response.statusText}`);
    }

    store.setStatementsList(statementsList);
    store.setShowPaymentSpinner(false);

    return response.ok;
};

const fetchSavedCards = async (store: PaymentStore) => {
    if (!paymentStore) {
        paymentStore = store;
    }

    let clientCreditCard: ClientCreditCard = {
        success: false,
        friendlyMessage: '',
        creditCards: []
    };

    const response = await http<undefined, ClientCreditCard>({
        path: `payment/getCards`
    });
    if (response.ok && response.parsedBody) {
        if (response.parsedBody.success) {
            clientCreditCard = response.parsedBody;
        }
    } else {
        layoutStore.setShowNotification(true, NoticationTypes.error, `API Error: ${response.statusText}`);
    }

    store.setClientCreditCard(clientCreditCard);
    return response.ok;
};

const processPayment = async (statement: Statement, gateway: string, tsysKey: string = '') => {
    paymentStore.setShowPaymentSpinner(true);
    paymentStore.setFailPaymentMessage(false, "", 0, 0, 0);

    const submitPayment: SubmitPayment = {
        rowNum: statement.rowNum,
        noteID: statement.noteID,
        salid: statement.salid,
        amount: paymentStore.paymentAmount,
        ccNumber: paymentStore.ccNumber,
        ccExpiry: paymentStore.ccExpiry,
        ccCVV: paymentStore.ccCVV,
        isManual: paymentStore.isManualPayment,
        isSalPayment: paymentStore.isSalPayment,
        saveCard: paymentStore.saveCardToProfile,
        cccid: paymentStore.selectedCreditCard.cccid,
        amountInvoiced: statement.amountInvoiced,
        gatewayName: gateway,
        tsysKey: tsysKey,
        utcOffset: 0
    };

    const d = new Date();
    submitPayment.utcOffset = -d.getTimezoneOffset()/60;

    const response = await http<SubmitPayment, SubmitPaymentResponse>({
        method: "POST",
        body: submitPayment,
        path: `payment/submit`
    });

    paymentStore.setShowPaymentSpinner(false);

    if (response.ok && response.parsedBody) {
        paymentStore.clearCreditCard();

        if (response.parsedBody.success) {
            paymentStore.setFailPaymentMessage(false, '', response.parsedBody.noteBillingId, statement.salid, statement.noteID );
            paymentStore.clearSelectedStatementRowNum();
            paymentStore.setManualPayment(false);
            paymentStore.resetIsSalPayment();
            paymentStore.setBalanceDue(response.parsedBody.balanceDue);

            layoutStore.setShowNotification(true, NoticationTypes.success, response.parsedBody.friendlyMessage);
            //scrollToList();

            //await fetchStatementList(paymentStore, layoutStore);
        } else {
            paymentStore.setFailPaymentMessage(true, response.parsedBody.friendlyMessage, 0, 0, 0);
            //layoutStore.setShowNotification(true, NoticationTypes.error, response.parsedBody.friendlyMessage);
        }
    } else {
        layoutStore.setShowNotification(true, NoticationTypes.error, `API Error: ${response.statusText}`);
    }

    return response.ok;
};

const processSaveCard = async (gateway: string, tsysKey: string = '') => {
    paymentStore.setShowPaymentSpinner(true);

    const submitSaveCard: SubmitSaveCard = {
        ccNumber: paymentStore.ccNumber,
        ccExpiry: paymentStore.ccExpiry,
        ccCVV: paymentStore.ccCVV,
        gatewayName: gateway,
        tsysKey: tsysKey,
    };

    const response = await http<SubmitSaveCard, SubmitPaymentResponse>({
        method: "POST",
        body: submitSaveCard,
        path: `payment/saveCard`
    });

    paymentStore.setShowPaymentSpinner(false);

    if (response.ok && response.parsedBody) {
        paymentStore.clearCreditCard();

        if (response.parsedBody.success) {
            layoutStore.setShowNotification(true, NoticationTypes.success, response.parsedBody.friendlyMessage);
            paymentStore.setFailPaymentMessage(false, '', 0,0,0);
            paymentStore.clearSelectedStatementRowNum();
            paymentStore.setManualPayment(false);
            scrollToList();

            await fetchStatementList(paymentStore, layoutStore);
        } else {
            paymentStore.setFailPaymentMessage(true, response.parsedBody.friendlyMessage, 0,0,0);
            //layoutStore.setShowNotification(true, NoticationTypes.error, response.parsedBody.friendlyMessage);
        }
    } else {
        layoutStore.setShowNotification(true, NoticationTypes.error, `API Error: ${response.statusText}`);
    }

    return response.ok;
};

const fetchPaymentHistory = async (store: PaymentStore, loStore: LayoutStore) => {
    if (!paymentStore) {
        paymentStore = store;
    }
    if (!layoutStore) {
        layoutStore = loStore;
    }

    store.setShowPaymentSpinner(true);

    let paymentsList: PaymentsList = {
        success: false,
        friendlyMessage: '',
        payments: []
    };

    const response = await http<undefined, PaymentsList>({
        path: `payment/getPayments`
    });
    if (response.ok && response.parsedBody) {
        if (response.parsedBody.success) {
            response.parsedBody.payments.forEach(m => {
                m.paidDate = dateToLocalDate(m.paidDate);
                if (m.serviceDate) {
                    m.serviceDate = dateToLocal(m.serviceDate);
                }
            })
            paymentsList = response.parsedBody;
        }
        else {
            layoutStore.setShowNotification(true, NoticationTypes.error, response.parsedBody.friendlyMessage);
        }
    } else {
        layoutStore.setShowNotification(true, NoticationTypes.error, `API Error: ${response.statusText}`);
    }

    store.setPaymentsList(paymentsList);
    store.setShowPaymentSpinner(false);

    return response.ok;
};


const sendContactBilling = async (store: PaymentStore, message: string, billingContactEmail: string) => {
    if (!paymentStore) {
        paymentStore = store;
    }
    store.setShowPaymentSpinner(true);

    const response = await http<SendContactBillingRequest, SendContactBillingResponse>({
        method: "POST",
        body: {
            sendToEmail: billingContactEmail,
            message: message
        },
        path: `payment/sendContactBilling`
    });
    store.setShowPaymentSpinner(false);

    if (response.ok && response.parsedBody) {
        if (response.parsedBody.success) {
            layoutStore.setShowNotification(true, NoticationTypes.success,"The Message has been Sent!");
            return response.parsedBody;
        } else {
            layoutStore.setShowNotification(true, NoticationTypes.error, response.parsedBody.friendlyMessage);
        }
    } else {
        layoutStore.setShowNotification(true, NoticationTypes.error, `API Error: ${response.statusText}`);
    }
};

const fetchReportToken = async (store: PaymentStore) => {

    const response = await http<string, ReportTokenResponse>({
        method: "POST",
        body: "",
        path: `payment/getReportToken`
    });

    if (response.ok && response.parsedBody) {
        store.setReportToken(response.parsedBody.reportToken);
    } else {
        store.setReportToken("");
    }

    return response.ok;
}

const cancelPayment = () => {
    paymentStore.clearSelectedStatementRowNum();
    paymentStore.setManualPayment(false);

    if (paymentStore.showFailPayment) {
        paymentStore.setFailPaymentMessage(false, "", 0, 0, 0);
    }

    scrollToList();
};

const scrollToList = () => {
    const element = document.querySelector('#divStatementList');
    element!.scrollIntoView({ behavior: 'smooth' });
};

export const paymentService = {
    fetchStatementList,
    processPayment,
    cancelPayment,
    fetchSavedCards,
    processSaveCard,
    fetchPaymentHistory,
    sendContactBilling,
    fetchReportToken
}