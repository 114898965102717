import { ChangePasswordModel, ChangePasswordResponse } from '../models/ChangePassword';
import { ClientStore } from '../stores/clientStore';
import { PasswordStore } from '../stores/passwordStore';
import { authenticationService } from './authentication.service';
import { http } from './http';

let passwordStore: PasswordStore;
let clientStore: ClientStore;

const setStores = (pStore: PasswordStore, cStore: ClientStore) =>
{
    passwordStore = pStore;
    clientStore = cStore;
};

const savePasswordChange = async (change: ChangePasswordModel) => {

    passwordStore.setShowSpinner(true);
    change.newPassword = change.newPassword.trim();

    const response = await http<ChangePasswordModel, ChangePasswordResponse>({
        method: "POST",
        body: change,
        path: `profile/changePassword`
    });

    passwordStore.setShowSpinner(false);

    if (response.ok && response.parsedBody) {
        if (response.parsedBody.success) {
            clientStore.setShowPasswordDialog(false);
            sessionStorage.setItem("password-open", "");
            authenticationService.logout();

            return response.parsedBody;
        } else {
            passwordStore.setChangeResponse(false, response.parsedBody.friendlyMessage);
        }
    } else {
        passwordStore.setChangeResponse(false, `API Error: ${response.statusText}`);
    }

    return response.ok;
};

const checkLogin = async () => {
    await http<undefined, undefined>({
        path: `profile/checkLogin`
    }).then(res => {
        if (res.status === 200) {
            sessionStorage.setItem("password-open", "");
        }
    });
}

export const passwordService = {
    savePasswordChange,
    checkLogin,
    setStores
}
