import React, {FC} from 'react';

interface Props {
    date: string;
}

export const LongDate: FC<Props> = ({date}) => {

    //assume date should be in UTC
    if (!date.endsWith('Z')) {
        date = date + 'Z';
    }

    const lastLoginDate = new Date(date);
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute:'numeric' };

    return(
        <>
            {lastLoginDate.toLocaleDateString("en-US", options)}
        </>
    )
}