import { useRef, useState } from 'react';
import { Col, Row } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
import { CalendarComponent, ChangedEventArgs, NavigatedEventArgs, RenderDayCellEventArgs } from '@syncfusion/ej2-react-calendars';
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-react-calendars/styles/material.css';
import '../../../assets/styles/scheduler.scss';
import { FloatingLabel, Label } from "@progress/kendo-react-labels";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Button } from '@progress/kendo-react-buttons';
import { useAppointmentStore, useLayoutStore, usePractitionerStore, useSettingStore } from '../../../hooks/hooks';
import { appointmentService } from '../../../services';
import { observer } from 'mobx-react';
import { BookAppointment } from '../../../models/Appointments';
import Practitioner from './Practitioner';
import { RadioButton, RadioButtonChangeEvent } from '@progress/kendo-react-inputs';
import { dateToLocal, dateToLocalDate, dateToUTC } from '../../../helpers';
import { LoadingSpinner } from '../../shared/LoadingSpinner';
import { addClass } from '@syncfusion/ej2-base';
import { SaveAppointmentStatus } from '../../../models/enums';

const ScheduleSelection = observer(() => {
    const practitionerStore = usePractitionerStore();
    const layoutStore = useLayoutStore();
    const appointmentStore = useAppointmentStore();
    const settingStore = useSettingStore();
    const timeSlot = useRef<HTMLDivElement>(null);

    const calendar = useRef<CalendarComponent | null>(null);

    const [loadingAvail, setLoadingAvail] = useState(false);

    const billingCodeChange = async (event: DropDownListChangeEvent): Promise<void> => {
        practitionerStore.setSelectedBillingCode(event.value);
        practitionerStore.setApptType("InPerson");

        practitionerStore.setMorningTimeSlots([]);
        practitionerStore.setAfternoonTimeSlots([]);
        practitionerStore.setEveningTimeSlots([]);

        var currentMonth = `${practitionerStore.selectedDate.getMonth() + 1}-${practitionerStore.selectedDate.getFullYear()}`;
        await getAvailableDates(currentMonth);

        practitionerStore.setEnableTimeSection(true);
    }

    const handleDateChange = (event: ChangedEventArgs) => {
        practitionerStore.setSelectedDate(event.value!);
        dateChange(event.value!);
    }

    const dateChange = (selDate: Date) => {
        //appointmentStore.setAddAppointmentMessage("");
        var availableDays = practitionerStore.practitionerAvailability.availableDays;

        var day = availableDays.find((value, index) => {
            var jsonDate = new Date(value.day);
            return jsonDate.getDate() === selDate.getDate() && jsonDate.getMonth() === selDate.getMonth();
        });

        if (day) {
            updateTimeSlots(day!.timeSlots);
        } else {
            practitionerStore.setMorningTimeSlots([]);
            practitionerStore.setAfternoonTimeSlots([]);
            practitionerStore.setEveningTimeSlots([]);
        }
    }

    const updateTimeSlots = (availableTimeSlots: Date[]) => {
        const morning: string[] = [];
        const afternoon: string[] = [];
        const evening: string[] = [];

        availableTimeSlots.map((timeSlot: Date) => {
            const local = dateToLocal(timeSlot.toString());
            const tDate = new Date(local);

            if (tDate > new Date()) {
                const time = local.split(',')[1].trimLeft();
                const [edtHour] = time.split(':');

                let edtHourNum = Number(edtHour);
                if (time.includes("PM") && edtHourNum !== 12) {
                    edtHourNum += 12;
                }

                if (time.includes("AM")) {
                    morning.push(time);
                } else if (edtHourNum >= 12 && edtHourNum < 17) {
                    afternoon.push(time);
                } else {
                    evening.push(time);
                }
            }
            return timeSlot;
        });

        practitionerStore.setMorningTimeSlots(morning);
        practitionerStore.setAfternoonTimeSlots(afternoon);
        practitionerStore.setEveningTimeSlots(evening);
    }

    const getAvailableDates = async (month: string) => {
        if (practitionerStore.selectedBillingCode!.billingCodeID === 0) {
            return;
        }

        setLoadingAvail(true);
        await appointmentService.fetchPractitionerAvailability(practitionerStore, month, '', '',
            practitionerStore.selectedPractitioner!.id,
            practitionerStore.selectedBillingCode!.billingCodeID, practitionerStore.apptType === "Telehealth")
            .then(() => {
                calendar.current!.refresh();

                practitionerStore.setSelectedTimeslot('');
                practitionerStore.setIsTimeSelected(true);
                dateChange(practitionerStore.selectedDate);
                setLoadingAvail(false);
            });

    };

    const handleCalendarClick = async (args: NavigatedEventArgs) => {
        if (args.view === "Month") {
            var currentMonth = `${args.date!.getMonth() + 1}-${args.date!.getFullYear()}`;
            practitionerStore.setSelectedDate(args.date!);

            await getAvailableDates(currentMonth);
        }
    };

    const onRenderDayCell = (args: RenderDayCellEventArgs) => {
        const now = new Date();

        if (args.date?.toDateString() === now.toDateString()) {
            addClass([args.element!], ['today-color'])
        }

        args.isDisabled = true;
        now.setTime(now.getTime() - (60 * 60 * 1000));
        if (args.date! < now || practitionerStore.selectedBillingCode.billingCodeID === 0) {
            return;
        }

        var availableDays = practitionerStore.practitionerAvailability.availableDays;
        var hasDay = availableDays.findIndex((value, index) => {
            var jsonDate = new Date(value.day);
            return jsonDate.getDate() === args.date?.getDate() && jsonDate.getMonth() === args.date?.getMonth();
        });

        if (hasDay >= 0) {
            args.isDisabled = false;
        }
    }

    const getButtonStyle = (index: number, timeSlot: string) => {
        if (practitionerStore.selectedButtonIndex === index && practitionerStore.selectedTimeslot === timeSlot) {
            return { backgroundColor: '#517EB9', color: 'white' };
        }
    }

    const setLgShow = (index: number, timeSlot: string) => {
        practitionerStore.setSelectedButtonIndex(index);
        practitionerStore.setSelectedTimeslot(timeSlot);
        practitionerStore.setIsTimeSelected(false);
    }

    const handleSubmit = async () => {
        const dateNotime = dateToLocalDate(practitionerStore.selectedDate.toString());
        const newDate = new Date(dateNotime + ' ' + practitionerStore.selectedTimeslot);
        const utcDateString = dateToUTC(newDate);
        const formattedTime = practitionerStore.selectedTimeslot;

        await appointmentService.saveAppointment(practitionerStore, layoutStore, appointmentStore, utcDateString, formattedTime,
            practitionerStore.selectedPractitioner!.id,
            practitionerStore.selectedBillingCode!.billingCodeID,
            practitionerStore.apptType === "TeleHealth",
            settingStore.getSetting().pendingAppointment
        )
            .then(async (apptResponse: BookAppointment) => {
                if (apptResponse.success) {
                    await appointmentService.fetchAppointments(appointmentStore, layoutStore).then(() => {
                        //practitionerStore.setShowNewAppointment(false);
                        //appointmentStore.setAddAppointmentMessage("");
                    });

                    await appointmentService.fetchPractitioner(practitionerStore, layoutStore)
                }
            }
        );
    }

    const isButtonDisabled = practitionerStore.isTimeSelected ||
                             !practitionerStore.enableTimeSection ||
                             practitionerStore.selectedBillingCode.billingCodeID === 0;

    return (
        <div className='scheduler'>
            <Row md={12} className="mainlayout">
                {practitionerStore.practitioners && practitionerStore.practitioners.length > 0 && !practitionerStore.selectedPractitioner &&
                    <div className="available-providers">
                        <Row>
                            <Col className='text-center available-providers-text'><h5>Available Providers</h5></Col>
                        </Row>
                        <Row id="practitioner-card-row">
                            {practitionerStore.practitioners.map((doc, index) => (
                                <Col key={index} md={6} className='mainlayouttablet'>
                                    <Practitioner practitioner={doc}></Practitioner>
                                </Col>
                            ))}
                        </Row>
                    </div>
                }
                {practitionerStore.selectedPractitioner &&
                    <div className="selected-practitioner">
                        <Col className="practitioner-card">
                            <Practitioner practitioner={practitionerStore.selectedPractitioner}></Practitioner>
                        </Col>

                        <div className="set-appointment">
                            <Col className='mt-2 calendar-section'>
                                <FloatingLabel className="floating-label" label={"What is the Purpose for your visit?"} editorId={"BillingCodes"} editorValue={practitionerStore.selectedBillingCode.billingCodeDescription}>
                                    <DropDownList id="BillingCodes"
                                        textField='billingCodeDescription'
                                        dataItemKey='billingCodeID'
                                        data={practitionerStore.billingCodeSelections}
                                        value={practitionerStore.selectedBillingCode}
                                        onChange={billingCodeChange}
                                        disabled={appointmentStore.addAppointmentMessage.length > 0 && appointmentStore.apptSaveStatus !== SaveAppointmentStatus.Failed}
                                    />
                                </FloatingLabel>
                                {settingStore.getSetting().allowTelehealth &&
                                    <div>
                                        <Label className='mt-2'>Appointment Type:</Label>
                                        <div className='pl-2 mb-4'>
                                            <RadioButton name='apptType' label='In-Person' value="InPerson" checked={practitionerStore.apptType === "InPerson"} onChange={(e: RadioButtonChangeEvent) => practitionerStore.setApptType(e.value)}></RadioButton>
                                            <RadioButton className='ml-2' name='apptType' label='Telehealth' value="TeleHealth" disabled={!practitionerStore.selectedBillingCode.allowTelehealth} checked={practitionerStore.apptType === "TeleHealth"} onChange={(e: RadioButtonChangeEvent) => practitionerStore.setApptType(e.value)}></RadioButton>
                                        </div>
                                    </div>
                                }
                                <div className='calenderlayouttablet'>
                                    <div className='divlayout' >
                                        <div className='selectdate'>Select Your Date</div>
                                        <div className='calendar e-calendar'>
                                            <CalendarComponent id='calendar' value={practitionerStore.selectedDate}
                                                ref={calendar}
                                                navigated={handleCalendarClick}
                                                onChange={handleDateChange}
                                                renderDayCell={onRenderDayCell}
                                                enabled={(!loadingAvail && practitionerStore.selectedBillingCode.billingCodeID > 0 && appointmentStore.addAppointmentMessage.length === 0) || appointmentStore.apptSaveStatus === SaveAppointmentStatus.Failed}
                                                onClick={() => {
                                                    if (timeSlot.current)
                                                        timeSlot.current.scrollTo({
                                                            top: 0,
                                                            behavior: "smooth"
                                                        });
                                                }}
                                            >
                                            </CalendarComponent>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <div className='vertical-line'></div>

                            <Col className='timelayouttablet'>
                                <div className='divlayouttime' style={{ opacity: practitionerStore.enableTimeSection ? "100%" : "33%" }} ref={timeSlot}>
                                    <div className='selecttime'>Select Your Time</div>
                                    <LoadingSpinner show={loadingAvail} />
                                    <div className='timebutton'>
                                        <div className='timeSlot'>Morning</div>
                                        {practitionerStore.morningTimeSlots.length > 0 ? (
                                            <div className='column .col-4 morningslot'>
                                                {practitionerStore.morningTimeSlots.map((timeSlot, index) => (
                                                    <Button
                                                        key={index}
                                                        style={getButtonStyle(index, timeSlot)}
                                                        className="slot"
                                                        size="small"
                                                        onClick={() => setLgShow(index, timeSlot)}
                                                    >
                                                        {timeSlot}
                                                    </Button>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className='unavailableslots'>Slots not available</div>
                                        )}
                                        <div className='timeSlot mt-3'>Afternoon</div>
                                        {practitionerStore.afternoonTimeSlots.length > 0 ? (
                                            <div className='column .col-5 afternonslot'>
                                                {practitionerStore.afternoonTimeSlots.map((timeSlot, index) => (
                                                    <Button
                                                        key={index}
                                                        style={getButtonStyle(index, timeSlot)}
                                                        className='slot'
                                                        size="small"
                                                        onClick={() => setLgShow(index, timeSlot)}
                                                    >
                                                        {timeSlot}
                                                    </Button>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className='unavailableslots'>Slots not available</div>
                                        )}
                                        <div className='timeSlot mt-3'>Evening</div>
                                        {practitionerStore.eveningTimeSlots.length > 0 ? (
                                            <div className='column .col-4 eveningslot'>
                                                {practitionerStore.eveningTimeSlots.map((timeSlot, index) => (
                                                    <Button
                                                        key={index}
                                                        style={getButtonStyle(index, timeSlot)}
                                                        className='slot'
                                                        size="small"
                                                        onClick={() => setLgShow(index, timeSlot)}
                                                    >
                                                        {timeSlot}
                                                    </Button>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className='unavailableslots'>Slots not available</div>
                                        )}
                                    </div>
                                </div>
                                <div className='time-slot-footer'>
                                    {(appointmentStore.addAppointmentMessage.length === 0 || appointmentStore.apptSaveStatus === SaveAppointmentStatus.Failed) &&
                                        <div>
                                            <Button className='add-appointment-button' type="button" onClick={handleSubmit} disabled={isButtonDisabled}>Request New Appointment</Button>
                                        </div>
                                    }

                                    <div className='message' style={{ color: appointmentStore.apptSaveStatus === SaveAppointmentStatus.Failed ? "#b90202" : "#5f945f" }}>
                                        {appointmentStore.addAppointmentMessage}
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </div>
                }
            </Row>
        </div>
    )
});

export default ScheduleSelection;