import { dateToLocal } from '../helpers';
import { ActivityLog } from '../models/ActivityLog';
import { ActivityStore } from '../stores/activityStore';
import { LayoutStore } from '../stores/layoutStore';
import { http } from './http';
import { NoticationTypes } from '../models/enums';

const fetchActivityLog = async (store: ActivityStore, layoutStore: LayoutStore) => {
    store.setShowActivitySpinner(true);

    let activityLog: ActivityLog = {
        success: false,
        friendlyMessage: '',
        totalRecords: 3,
        activity: []
    }

    const response = await http<undefined, ActivityLog>({
        path: `profile/activity`
    });
    if (response.ok && response.parsedBody) {
        if (response.parsedBody.success) {
            response.parsedBody.activity.forEach(m => {
                m.logDate = dateToLocal(m.logDate);
            })
            activityLog = response.parsedBody;
        }
        else {
            layoutStore.setShowNotification(true, NoticationTypes.error, response.parsedBody.friendlyMessage);
        }
    } else {
        layoutStore.setShowNotification(true, NoticationTypes.error, `API Error: ${response.statusText}`);
    }

    store.setActivityLog(activityLog);
    store.setShowActivitySpinner(false);

    return response.ok;
}

export const activityService = {
    fetchActivityLog
}