import { makeAutoObservable } from 'mobx';
import { BillingCodes, PractitionerAvailability, PractitionerData } from './../../src/models/Practitioner';

export class PractitionerStore {

    practitionerData: PractitionerData = {
        id: 0,
        doctorName: '',
        education: '',
        experience: '',
        specialization: '',
        description: '',
        hospitalName: '',
        address: '',
        imageUrl: '',
        userImage: '',
        hasExistingAppt: 0
    };

    showNewAppointment: boolean = false;
    practitioners: PractitionerData[] = [];
    selectedPractitioner: PractitionerData | undefined;

    practitionerAvailability: PractitionerAvailability = {
        availableDays: [],
        success: false,
        friendlyMessage: ''
    };

    unAvailableDates : any = [];
    nextMonthDates : any = [];
    previousMonthDates : any = [];
    userImage : any = '';
    formatedSpecialization : string = '';

    billingCodeSelections: BillingCodes[] = [];
    selectedBillingCode: BillingCodes = {
        billingCodeID: 0,
        billingCodeDescription: '',
        isDefault: 0,
        allowTelehealth: false
    };
    enableTimeSection : boolean = false;
    morningTimeSlots : string[] = [];
    afternoonTimeSlots : string[] = [];
    eveningTimeSlots : string[] = [];
    selectedDate : Date = new Date();
    selectedTimeslot : string = '';
    apptType : string = 'InPerson';
    isTimeSelected : boolean = true;
    selectedButtonIndex : number | null = null;

    setShowNewAppointment = (show: boolean) => {
        this.showNewAppointment = show;
        if (show) {
            this.setClearSelectedPractitioner();
        }
    };

    setEnableTimeSection = (enable: boolean) => {
        this.enableTimeSection = enable;
    };

    setMorningTimeSlots = (slots: string[]) => {
        this.morningTimeSlots = slots;
    };
    setAfternoonTimeSlots = (slots: string[]) => {
        this.afternoonTimeSlots = slots;
    };
    setEveningTimeSlots = (slots: string[]) => {
        this.eveningTimeSlots = slots;
    };

    setSelectedDate = (selected: Date) => {
        this.selectedDate = selected;
    };

    setSelectedTimeslot = (slot: string) => {
        this.selectedTimeslot = slot;
    };

    setApptType = (type: string) => {
        this.apptType = type;
    };

    setIsTimeSelected = (selected: boolean) => {
        this.isTimeSelected = selected;
    };

    setSelectedButtonIndex = (indx: number) => {
        this.selectedButtonIndex = indx;
    };

    setBillingCodeSelections = (billingCodes: BillingCodes[]) => {
        this.billingCodeSelections = billingCodes;
    };

    setSelectedBillingCode = (billingCode: BillingCodes) => {
        this.selectedBillingCode = billingCode;
    };

    setPractitionerSpecialization = (specialization: string) => {
        this.practitionerData.specialization = specialization;
    };

    setPractitionerData = (practitionerData: PractitionerData) => {
        this.practitionerData = practitionerData;
    };

    setPractitioners = (practitioners: PractitionerData[]) => {
        this.practitioners = practitioners;

        this.practitioners.forEach(doc => {
            if (doc.specialization) {
                const array = JSON.parse(doc.specialization);
                const specializationString = array.join(", ");
                doc.specialization = specializationString;
            }
        });

        if (this.practitioners.length === 1 && !this.practitioners[0].hasExistingAppt) {
            this.selectedPractitioner = this.practitioners[0];
        }
    };

    setSelectedPractitioner = (doc: PractitionerData) => {
        this.selectedPractitioner = doc;
    };

    setClearSelectedPractitioner = () => {
        this.selectedPractitioner = undefined;
        this.selectedBillingCode = {
            billingCodeID: 0,
            billingCodeDescription: '',
            isDefault: 0,
            allowTelehealth: false
        };

        this.morningTimeSlots = [];
        this.afternoonTimeSlots = [];
        this.eveningTimeSlots = [];
    };

    setPractitionerAvailability = (avail: PractitionerAvailability) => {
         this.practitionerAvailability = avail;
    };

    setDates = (unAvailableDates:any)=> {
        this.unAvailableDates = unAvailableDates;
    };

    setNextMonthDates = (nextMonthDates:any) => {
        this.nextMonthDates = nextMonthDates;
    };

    setPreviousMonthDates = (previousMonthDates:any) => {
        this.previousMonthDates = previousMonthDates;
    };

    setUserImage =(userImage:any) => {
        this.userImage = userImage
    };

    setPractitiionerUserImage =(id: number, userImage:any) => {
        if (this.practitionerData)
        {
            var doc = this.practitioners.find(p => p.id === id);
            if (doc) {
                doc.userImage = userImage;
            }
        }
    };

    setSpecialization = (special: string ) => {
        this.formatedSpecialization = special;
        this.practitionerData.specialization = special;
    }

    constructor() {
        makeAutoObservable(this)
    };
}