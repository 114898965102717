import React, { FC } from 'react';
import { Button, Card, CardTitle, CardImg } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { ClientUser } from '../../models/User';
import { useClientStore, useSettingStore } from '../../hooks/hooks';
import insurance_icon from '../../assets/images/insurance_info_icon.png';import { authenticationService } from '../../services';

interface Props {
    user: ClientUser;
}

export const HomeInsurance: FC<Props> = ({ user }) => {
    const clientStore = useClientStore();
    const settingStore = useSettingStore();
    const history = useHistory();
    const currentUser = authenticationService.currentUserValue;

    const gotoInsurance = () => {
        let idx = 3;
        if (!settingStore.getSetting().showBasics) idx -= 1;
        if (!settingStore.getSetting().showDetails) idx -= 1;

        clientStore.setSelectedTab(idx);
        history.push('/profile')
    }

    return (
        <div className="sections">
            <Card className='custom_card'>
                <div className="card_heading">
                    <CardImg className='icons' src={insurance_icon} ></CardImg>
                    <CardTitle tag="h5">Insurance Information</CardTitle>
                </div>
                <div className="status">
                    {/* <CardText tag="p">
                        {isInsuranceUpToDate &&
                            <>Your information is up to date.</>
                        }
                        {!isInsuranceUpToDate &&
                            <>You need to update your information.</>
                        }
                    </CardText> */}
                </div>

                <div className="instructions">
                    <i>Insurance</i> is where you provide the Company, Group Number, Subscriber ID etc.
                    { currentUser.licensePlan > 0 && settingStore.getSetting().profileRules.secondaryInsuranceView &&
                        <span> and any of Secondary Insurance you may have.</span>
                    }
                </div>
                <div className='menu_buttons'>
                    <Button size="sm" onClick={gotoInsurance}>Update Insurance</Button>
                </div>
            </Card>

        </div>
    )
}

export default HomeInsurance;