import { makeAutoObservable } from 'mobx'
import { Confirm } from '../models/Confirm'
import { PractitionerData } from '../models/Practitioner';

export class ConfirmStore {

    appointmentBookingDetails: Confirm = {
        appoinmentId: '',
        address: '',
        doctorName: '',
        education: '',
        experience: '',
        hospitalName: '',
        id: 0,
        imageUrl: '',
        specialization: '',
        appointmentDateAndTime:''
    };

    setPractitionerDetails = (practitioner: PractitionerData) => {
        this.appointmentBookingDetails.id = practitioner.id;
        this.appointmentBookingDetails.doctorName = practitioner.doctorName;
        this.appointmentBookingDetails.education = practitioner.education;
        this.appointmentBookingDetails.experience = practitioner.experience;
        this.appointmentBookingDetails.specialization = practitioner.specialization;
        this.appointmentBookingDetails.hospitalName = practitioner.hospitalName;
        this.appointmentBookingDetails.address = practitioner.address;
        this.appointmentBookingDetails.imageUrl = practitioner.imageUrl;
    };

    selectedDate : Date  = new Date();
    time : string = '';
    practitionerId : number = 0;
    appoinmentId : number = 0;
    otp : string = '' ;
    imageUrl : string = '';

    setBookingDetails = (selectedDate : Date,time : string,practitionerId:number) => {
        this.selectedDate = new Date(selectedDate);
        this.time = time;
        this.practitionerId = practitionerId;
    }

    setAppointmentId = (appoinmentId:number) => {
        this.appoinmentId = appoinmentId;
    }

    setOtp = (otp:string)=> {
        this.otp = otp;
    }

    setImageUrl = (imageUrl:string) => {
        this.imageUrl = imageUrl;
    }

    constructor() {
        makeAutoObservable(this)
    };
}