import { makeAutoObservable } from 'mobx';
import { basicDetails } from '../models/BasicDetails';

export class BasicDetailsStore {
    basicDetails: basicDetails = {
        firstName: '',
        lastName: '',
        mobileNumber: '',
        email: '',
        genderId: 0,
        dob: '',
        zipcode: '',
        preferredMode: '',
        insuranceNo: '',
        insuranceCompanyId: 0,
        insuranceCompanyName: '',
        notes: '',
        allowScheduling: false
    }

    setBasicInfo = (basicInfo: basicDetails) => {
        this.basicDetails = basicInfo;
    };

    constructor() {
        makeAutoObservable(this)
    };
}