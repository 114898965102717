const AppointmentsSVG = (props) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width={props.width} height={props.height} fill={props.stroke} stroke={props.stroke} viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                stroke={props.stroke} fill={props.stroke}>
                <path stroke={props.stroke} fill={props.stroke} d="M464 4770 c-117 -17 -222 -99 -266 -207 -22 -57 -23 -65 -26 -515
        l-3 -458 2039 0 c1121 0 2121 -3 2221 -7 l182 -6 -3 469 -3 469 -28 60 c-46
        98 -116 159 -217 189 -45 13 -114 16 -411 16 l-357 0 -4 -177 c-3 -161 -5
        -182 -25 -220 -87 -163 -318 -156 -388 12 -13 28 -18 84 -22 212 l-6 173 -757
        0 -757 0 -6 -173 c-7 -221 -25 -270 -117 -317 -113 -58 -234 -17 -296 100 -16
        30 -20 63 -22 213 l-4 177 -336 -1 c-185 -1 -360 -5 -388 -9z"/>
                <path stroke={props.stroke} fill={props.stroke} d="M542 3220 c-28 -26 -35 -71 -30 -182 4 -84 6 -93 32 -114 25 -22 36
        -24 142 -24 109 0 116 1 139 25 24 23 25 30 25 148 0 116 -1 125 -23 145 -21
        20 -34 22 -143 22 -106 0 -123 -2 -142 -20z"/>
                <path stroke={props.stroke} fill={props.stroke} d="M1226 3219 c-26 -20 -26 -22 -26 -145 0 -119 1 -126 25 -149 23 -24
        30 -25 139 -25 107 0 117 2 143 24 27 23 28 29 31 126 4 115 0 142 -28 170
        -18 18 -34 20 -139 20 -105 0 -121 -2 -145 -21z"/>
                <path stroke={props.stroke} fill={props.stroke} d="M1905 3215 c-24 -23 -25 -30 -25 -139 0 -63 3 -122 6 -131 13 -33 59
        -45 169 -45 104 0 107 1 136 29 29 29 29 31 29 146 0 110 -1 117 -25 140 -23
        24 -30 25 -145 25 -115 0 -122 -1 -145 -25z"/>
                <path stroke={props.stroke} fill={props.stroke} d="M2589 3221 c-22 -18 -24 -27 -27 -140 l-4 -121 31 -31 31 -31 121 4
        c159 4 159 4 159 168 0 104 -2 120 -21 144 -20 26 -22 26 -144 26 -106 0 -126
        -3 -146 -19z"/>
                <path stroke={props.stroke} fill={props.stroke} d="M3269 3214 c-23 -24 -24 -32 -24 -146 0 -119 0 -120 28 -144 26 -22
        36 -24 143 -24 109 0 116 1 139 25 24 23 25 30 25 148 0 116 -1 125 -23 145
        -21 20 -34 22 -143 22 -117 0 -121 -1 -145 -26z"/>
                <path stroke={props.stroke} fill={props.stroke} d="M3956 3219 c-26 -20 -26 -22 -26 -145 0 -119 1 -126 25 -149 23 -24
        30 -25 139 -25 107 0 117 2 143 24 27 23 28 29 31 126 4 115 0 142 -28 170
        -18 18 -34 20 -139 20 -105 0 -121 -2 -145 -21z"/>
                <path stroke={props.stroke} fill={props.stroke} d="M543 2536 c-28 -24 -28 -25 -28 -146 0 -121 0 -122 28 -146 26 -22
        36 -24 141 -24 99 0 117 3 140 21 26 20 26 22 26 149 0 127 0 129 -26 149 -23
        18 -41 21 -140 21 -105 0 -115 -2 -141 -24z"/>
                <path stroke={props.stroke} fill={props.stroke} d="M1225 2535 c-24 -23 -25 -30 -25 -149 0 -123 0 -125 26 -145 24 -19
        40 -21 144 -21 112 0 117 1 141 26 23 24 24 32 24 146 0 119 0 120 -28 144
        -26 22 -36 24 -143 24 -109 0 -116 -1 -139 -25z"/>
                <path stroke={props.stroke} fill={props.stroke} d="M1905 2535 c-24 -23 -25 -30 -25 -145 0 -115 1 -122 25 -145 23 -24
        30 -25 143 -25 164 0 166 2 170 145 4 122 0 147 -30 175 -19 18 -35 20 -140
        20 -113 0 -120 -1 -143 -25z"/>
                <path stroke={props.stroke} fill={props.stroke} d="M2593 2536 c-27 -24 -28 -27 -31 -140 l-4 -116 30 -30 c29 -29 34
        -30 122 -30 l91 0 50 42 49 41 0 104 c0 97 -2 106 -25 128 -23 24 -30 25 -139
        25 -107 0 -117 -2 -143 -24z"/>
                <path stroke={props.stroke} fill={props.stroke} d="M3600 2558 c0 -3 30 -11 68 -18 227 -39 463 -160 647 -332 254 -237
        395 -568 395 -928 0 -354 -125 -656 -375 -905 -183 -183 -395 -299 -639 -349
        -44 -9 -82 -19 -85 -23 -10 -10 205 7 292 23 449 85 816 400 971 832 93 260
        93 584 0 844 -154 432 -526 750 -972 832 -87 16 -302 33 -302 24z"/>
                <path stroke={props.stroke} fill={props.stroke} d="M3619 1931 c-24 -19 -24 -19 -24 -252 l0 -232 73 -1 72 -1 0 233 c0
        219 -1 233 -20 252 -25 25 -71 26 -101 1z"/>
                <path stroke={props.stroke} fill={props.stroke} d="M545 1857 c-27 -23 -29 -30 -33 -115 -5 -108 2 -156 29 -183 17 -17
        34 -19 142 -19 116 0 125 1 145 23 20 21 22 34 22 144 0 167 0 167 -158 171
        -114 3 -120 3 -147 -21z"/>
                <path stroke={props.stroke} fill={props.stroke} d="M1245 1868 c-41 -22 -45 -38 -45 -163 0 -114 1 -123 23 -143 21 -20
        34 -22 145 -22 169 0 177 9 170 190 -3 97 -4 103 -31 126 -26 22 -36 24 -135
        24 -59 0 -116 -6 -127 -12z"/>
                <path stroke={props.stroke} fill={props.stroke} d="M1911 1854 l-31 -26 0 -119 c0 -114 1 -121 25 -144 23 -24 30 -25
        145 -25 115 0 122 1 145 25 24 23 25 30 25 140 0 115 0 117 -29 146 -29 29
        -31 29 -139 29 -104 0 -112 -1 -141 -26z"/>
                <path stroke={props.stroke} fill={props.stroke} d="M3835 1280 l0 -70 148 0 c134 0 149 2 167 20 11 11 20 33 20 50 0 17
        -9 39 -20 50 -18 18 -33 20 -167 20 l-148 0 0 -70z"/>
            </g>
        </svg>


    );
}

export default AppointmentsSVG;