import appSettings from '../AppSettings.json';
import { authenticationService } from '../services';

export interface HttpRequest<REQB> {
  path: string;
  method?: string;
  body?: REQB;
  headers?: HeadersInit | undefined;
  //accessToken?: string;
}
export interface HttpResponse<RESB> extends Response {
  parsedBody?: RESB;
}

export const http = <REQB, RESB>(config: HttpRequest<REQB>): Promise<HttpResponse<RESB>> => {
  return new Promise((resolve, reject) => {
    const request = new Request(`${appSettings.api_url}${config.path}`, {
      method: config.method || 'get',
      headers: config.headers || {'Content-Type': 'application/json'},
      body: config.body ? JSON.stringify(config.body) : undefined,
      credentials: 'include'
    });

    let bearerToken = '';
    if (!authenticationService.currentUser || !authenticationService.currentUserValue) {
      let userToken = sessionStorage.getItem('userToken');
      if(!userToken || userToken.trim().length <= 0) {
        return null;
      }
      bearerToken = userToken;
    } else {
      bearerToken = authenticationService.currentUserValue.token;
    }

    request.headers.set('authorization', `bearer ${bearerToken}`);
    request.headers.append('phostdomain', window.location.hostname);

    let response: HttpResponse<RESB>;

    fetch(request)
      .then(res => {
        response = res;
        const headerType = res.headers.get('Content-Type');

        if (headerType && headerType.indexOf('json') > 0) {
          return res.json();
        }
        if (headerType && headerType.indexOf('html') > 0) {
          return res.text();
        } else {
          resolve(response);
        }
      })
      .then(body => {
        if (response.ok) {
          response.parsedBody = body;
          resolve(response);
        } else {
          if (response.status === 401) {
            authenticationService.logout();
            reject(response);
          } else {
            response.parsedBody = body!.message;
            resolve(response);
          }
        }
      })
      .catch(err => {
        console.error('http error: ', err);
        reject(err);
      });
  });
};