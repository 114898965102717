import { FC, ComponentType } from 'react';
import { Container } from 'reactstrap';
import { observer } from 'mobx-react';
import { Route, RouteProps } from 'react-router-dom';
import AppSchedulerHeader from './header/AppSchedulerHeader';

interface Props {
  className?: string
  children?: React.ReactNode
  title?:string
}

export const ApptSchedulerLayout: FC<Props> = observer(({ children, className, title}) => {
  return (
    <div style={{ display: 'contents' }} className={"appscheduler-layout " + className} >
      <AppSchedulerHeader title={title} ></AppSchedulerHeader>
      <Container >
        {children}
      </Container>
    </div>
  )
}
);

// Replace with `RouteProps`
interface IProps extends RouteProps {
  // should also specify for props here
  // if you have no idea how just leave it as any
  component: ComponentType<any>,
  className: string,
  title? : string
}

export const ApptSchedulerLayoutRoute = ({ component: Comp, title, className, ...rest }: IProps) => {
  return (
    <Route {...rest} render={matchProps => (
      <ApptSchedulerLayout className={className} title={title}>
        <Comp {...matchProps} />
      </ApptSchedulerLayout>
    )} />
  )
}