import React, { FC, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../../assets/styles/header.scss';
import { Badge } from 'reactstrap';
import { authenticationService } from '../../services';
import { ClientUser } from '../../models/User';
import { observer } from 'mobx-react';
import { useClientStore, useLibraryStore, useSettingStore } from '../../hooks/hooks';
import { Tooltip } from "@progress/kendo-react-tooltip";
import ChangePassword from '../account/ChangePassword';
import AvatarDialog from '../account/AvatarDialog';
import { Menu, MenuItem, MenuSelectEvent } from "@progress/kendo-react-layout";
import { Popup } from "@progress/kendo-react-popup";
import { cameraIcon, passwordIcon, logoutIcon } from "@progress/kendo-svg-icons";
import MenuIcon from "../../assets/images/Menu_Icon.png";
import HomeSVG from '../../assets/images/svg/homeSVG';
import FormSVG from '../../assets/images/svg/formSVG';
import ProfileSVG from '../../assets/images/svg/profileSVG';
import BuildingSVG from '../../assets/images/svg/buildingSVG';
import MessageSVG from '../../assets/images/svg/messageSVG';
import AppointmentsSVG from '../../assets/images/svg/AppointmentsSVG';
import { Col, Row } from 'reactstrap';
import { LongDate } from '../shared/LongDate';

interface Props {
    user: ClientUser;
}

interface SelectedState {
    homeIcon: boolean;
    chatIcon: boolean;
    userIcon: boolean;
    documentIcon: boolean;
    buildingIcon: boolean;
    appointmentsIcon: boolean;
    paymentIcon: boolean;
    activityIcon: boolean;
}

export const Header: FC<Props> = observer(({ user }) => {
    const [selected, setSelected] = React.useState<SelectedState>({
        homeIcon: false,
        chatIcon: false,
        userIcon: false,
        documentIcon: false,
        buildingIcon: false,
        appointmentsIcon: false,
        paymentIcon: false,
        activityIcon: false
    });

    const [hovered, setHovered] = React.useState<SelectedState>({
        homeIcon: false,
        chatIcon: false,
        userIcon: false,
        documentIcon: false,
        buildingIcon: false,
        appointmentsIcon: false,
        paymentIcon: false,
        activityIcon: false
    });

    const optionsList = ["homeIcon", "chatIcon", "userIcon", "documentIcon", "buildingIcon", "appointmentsIcon"];

    const svgSize = "16px";
    const libraryStore = useLibraryStore();
    const clientStore = useClientStore();
    const settingStore = useSettingStore();
    const location = useLocation();

    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
    const avatarDiv = useRef<HTMLDivElement>(null);
    const [show, setShow] = useState(false);
    const [url, setUrl] = useState(window.location.href);
    const currentUser = authenticationService.currentUserValue;
    let devTitle = settingStore.getSetting().title;
    let [pageTitle, setPageTitle] = useState("Dashboard");

    let title = settingStore.getSetting().title;
    if (!title) {
        title = user.profile.providerName;
    }

    useEffect(() => {
        let badge = document.getElementById("msg-count");
        if (settingStore.messageCount > 99) {
            badge?.classList.add("badge-overflow");
        }
        else {
            badge?.classList.remove("badge-overflow");
        }

        let location = window.location.href;
        const regex = new RegExp(/\/$/, "g");
        setSelected({
            homeIcon: regex.test(location) === true ? true : false,
            chatIcon: location.indexOf("messages") !== -1 ? true : false,
            userIcon: clientStore.selectTab < 3 && location.indexOf("profile") !== -1 ? true : false,
            documentIcon: location.indexOf("library") !== -1 ? true : false,
            buildingIcon: clientStore.selectTab === 3 && location.indexOf("profile") !== -1 ? true : false,
            appointmentsIcon: location.indexOf("allappointments") !== -1 ? true : false,
            paymentIcon: location.indexOf("payment") !== -1 ? true : false,
            activityIcon: location.indexOf("activity") !== -1 ? true : false
        });
    }, [clientStore.selectTab, url, settingStore.messageCount, location]);

    useEffect(() => {
        for (let key in selected) {
            if (selected[key as keyof SelectedState] === true) {
                switch (key.toLowerCase()) {
                    case "homeicon":
                        setPageTitle("Dashboard");
                        break;
                    case "chaticon":
                        setPageTitle("Messages");
                        break;
                    case "usericon":
                        setPageTitle("Profile");
                        break;
                    case "documenticon":
                        setPageTitle("Forms");
                        break;
                    case "buildingicon":
                        setPageTitle("Insurance");
                        break;
                    case "appointmentsicon":
                        setPageTitle("Appointments");
                        break;
                    case "paymenticon":
                        setPageTitle("Payment");
                        break;
                    case "activityicon":
                        setPageTitle("All Activity");
                        break;
                }
            }
        }
    }, [selected]);

    const navClickEvent = () => {
        setUrl(window.location.href);
    }

    useEffect(() => {
        window.addEventListener('resize', () => {
            setWindowWidth(window.innerWidth);
            const menuIcon = document.getElementById("menu-icon");
            if (window.innerWidth <= 1200 && menuIcon?.classList.contains("disappear-menu-icon")) {
                const backdrop = document.getElementById("menu-backdrop");
                if (backdrop != null)
                    backdrop.style.display = "block";
            }
        });
    });

    useEffect(() => {
        for (let option of optionsList) {
            let active = document.getElementsByClassName(option)[0];
            let background = document.getElementById(option);

            if (active && background) {
                active.classList.remove("inactive", "mobileInactive", "active", "mobileActive");
                if (selected[option as keyof SelectedState] || hovered[option as keyof SelectedState]) {
                    background?.classList.add("backgroundWhite");

                    if (windowWidth >= 1200) {
                        active.classList.add("active");
                        active.classList.remove("inactive");
                    }
                    else {
                        active.classList.add("mobileActive");
                        active.classList.remove("mobileInactive");
                    }
                }
                else {
                    background?.classList.remove("backgroundWhite");
                    if (windowWidth >= 1200) {
                        active.classList.add("inactive");
                        active.classList.remove("active");
                    }
                    else {
                        active.classList.add("mobileInactive");
                        active.classList.remove("mobileActive");
                    }
                }
            }
        }
    });

    useEffect(() => {
        if (user.profile.avatar && clientStore.avatarImage === "") {
            clientStore.setAvatarImage(user.profile.avatar);
        }

        document.addEventListener("click", (e) => {
            const width = window.innerWidth;
            const height = window.innerHeight;

            if (show && (e.clientX < width / 6 || e.clientY > height / 9))
                setShow(false);
        });
    }, [clientStore, show, user.profile.avatar]);

    const getIdxInsurance = () => {
        let idx = 3;
        if (!settingStore.getSetting().showBasics) idx -= 1;
        if (!settingStore.getSetting().showDetails) idx -= 1;

        return idx;
    }

    const closePasswordAvatarDialog = () => {
        clientStore.setShowPasswordDialog(false);
        clientStore.setShowAvatarDialog(false);
        authenticationService.logout(true);
    };

    const menuSelect = (e: MenuSelectEvent) => {
        if (e.item.text === 'Sign Out') {
            closePasswordAvatarDialog();
        } else if (e.item.text === 'Change Password') {
            clientStore.setShowAvatarDialog(false);
            clientStore.setShowPasswordDialog(true);
        } else if (e.item.text === 'Update Avatar') {
            clientStore.setShowAvatarDialog(true);
        }
    }

    const avatarClick = (e: any) => {
        setShow(true);
    }

    const menuIconEvent = () => {
        const element = document.getElementById("badges");
        if (element) {
            element.classList.add("menu-open");
        }

        const menuIcon = document.getElementById("menu-icon");
        if (menuIcon) {
            menuIcon.classList.add("disappear-menu-icon");
        }

        const backdrop = document.getElementById("menu-backdrop");
        if (backdrop != null)
            backdrop.style.display = "block";
    }

    const hideBackdrop = () => {
        const backdrop = document.getElementById("menu-backdrop");
        if (backdrop != null)
            backdrop.style.display = "none";
    };

    const optionSelectedEvent = (event: React.MouseEvent<HTMLDivElement>) => {
        const key = event.currentTarget.id as keyof SelectedState;
        setSelected((prev) => {
            const updatedState: SelectedState = {} as SelectedState;
            Object.keys(prev).forEach((k) => {
                updatedState[k as keyof SelectedState] = false;
            });
            updatedState[key] = true;
            return updatedState;
        });

        hideBackdrop();
    };

    const hoverEvent = (event: React.MouseEvent<HTMLDivElement>, flag: boolean) => {
        const key = event.currentTarget.id as keyof SelectedState;
        setHovered((prev) => {
            const updatedState: SelectedState = {} as SelectedState;
            Object.keys(prev).forEach((k) => {
                updatedState[k as keyof SelectedState] = false;
            });
            updatedState[key] = flag;
            return updatedState;
        });
    };

    const divClickEvent = () => {
        const element = document.getElementById("badges");
        if (element) {
            element.classList.remove("menu-open");
        }

        const menuIcon = document.getElementById("menu-icon");
        if (menuIcon) {
            menuIcon.classList.remove("disappear-menu-icon");
        }

        hideBackdrop();
    }

    const logoClicked = () => {
        setSelected({
            homeIcon: true,
            chatIcon: false,
            userIcon: false,
            documentIcon: false,
            buildingIcon: false,
            appointmentsIcon: false,
            paymentIcon: false,
            activityIcon: false
        });
    }

    const backgroundClicked = () => {
        const element = document.getElementById("badges");
        if (element) {
            element.classList.remove("menu-open");
        }

        const menuIcon = document.getElementById("menu-icon");
        if (menuIcon) {
            menuIcon.classList.remove("disappear-menu-icon");
        }

        hideBackdrop();
    }

    return (
        <>
            <Tooltip anchorElement="target" position="bottom">
                <div className="header">
                    <div className="menu-backdrop" id="menu-backdrop" onClick={backgroundClicked}></div>
                    <div className="logo">
                        <img src={MenuIcon} className="menu-icon" id="menu-icon" alt='MenuIcon' title="Menu" onClick={menuIconEvent}></img>
                        {settingStore.logoLoaded &&
                            <Link to="/"><img src={settingStore.logo} id="LogoLink" alt='Pimsy' title="Go to Home" onClick={logoClicked}></img></Link>
                        }
                    </div>
                    <div className="controls" onClick={navClickEvent}>
                        <div className="badges" id='badges' >
                            <div className='mt-1 badges-flex' onClick={divClickEvent}>
                                <div className="mobile-menu-header">
                                    {settingStore.logoLoaded &&
                                        <Link to="/"><img src={settingStore.logo} id="responsive-menu-logo" alt='Pimsy' title="Go to Home" onClick={logoClicked}></img></Link>
                                    }

                                    <span className="material-symbols-outlined closeIcon" onClick={backgroundClicked}>
                                        close
                                    </span>
                                </div>

                                <Link to="/" className="option-group-link"><div className="option-group" id="homeIcon" onMouseEnter={(e) => { hoverEvent(e, true) }} onMouseLeave={(e) => { hoverEvent(e, false) }} onClick={optionSelectedEvent}>
                                    <HomeSVG height={svgSize} width={svgSize} stroke={windowWidth <= 1200 ? (selected["homeIcon"] || hovered["homeIcon"] ? "#266CA0" : "black") : (selected["homeIcon"] || hovered["homeIcon"] ? "#266CA0" : "white")} className="icon" title="To Home"></HomeSVG>
                                    <p className="option-text homeIcon">Home</p>
                                </div></Link>

                                {settingStore.getSetting().showMessaging &&
                                    <Link to="/messages" id="MessagesBadge" className="option-group-link">
                                        <div className={`option-group ${settingStore.messageCount > 0 && "ChatIconSpacing"}`} id="chatIcon" onMouseEnter={(e) => { hoverEvent(e, true) }} onMouseLeave={(e) => { hoverEvent(e, false) }} onClick={optionSelectedEvent}>
                                            <MessageSVG height={svgSize} width={svgSize} stroke={windowWidth <= 1200 ? (selected["chatIcon"] || hovered["chatIcon"] ? "#266CA0" : "black") : (selected["chatIcon"] || hovered["chatIcon"] ? "#266CA0" : "white")} className="icon mt-1" title="View and Send Messages"></MessageSVG>
                                            {settingStore.messageCount > 0 &&
                                                <Badge pill large className="badge-floating" id="msg-count">{settingStore.messageCount > 999 ? "999+" : settingStore.messageCount}</Badge>
                                            }
                                            <p className="option-text chatIcon">Messages</p>
                                        </div>
                                    </Link>
                                }

                                <Link to="/profile" id="InfoBadge" onClick={() => clientStore.setSelectedTab(0)} className="option-group-link">
                                    <div className="option-group" id="userIcon" onClick={optionSelectedEvent} onMouseEnter={(e) => { hoverEvent(e, true) }} onMouseLeave={(e) => { hoverEvent(e, false) }}>
                                        <ProfileSVG height={svgSize} width={svgSize} stroke={windowWidth <= 1200 ? (selected["userIcon"] || hovered["userIcon"] ? "#266CA0" : "black") : (selected["userIcon"] || hovered["userIcon"] ? "#266CA0" : "white")} className="icon mt-1" title="Update Your Information"></ProfileSVG>
                                        <p className="option-text userIcon">Profile Information</p>
                                    </div>
                                </Link>

                                <Link to="/library" onClick={() => libraryStore.setSelectedTab(0)} id="FormsBadge" className="option-group-link">
                                    <div className="option-group" id="documentIcon" onClick={optionSelectedEvent} onMouseEnter={(e) => { hoverEvent(e, true) }} onMouseLeave={(e) => { hoverEvent(e, false) }}>
                                        <FormSVG height={svgSize} width={svgSize} stroke={windowWidth <= 1200 ? (selected["documentIcon"] || hovered["documentIcon"] ? "#266CA0" : "black") : (selected["documentIcon"] || hovered["documentIcon"] ? "#266CA0" : "white")} className="icon mt-1" title="Complete Forms"></FormSVG>
                                        <p className="option-text documentIcon">Complete Forms</p>
                                    </div>
                                </Link>

                                {settingStore.getSetting().showInsurance &&
                                    <Link to="/profile" onClick={() => clientStore.setSelectedTab(getIdxInsurance())} id="InsBadge" title="Insurance Information" className="option-group-link">
                                        <div className="option-group" id="buildingIcon" onMouseEnter={(e) => { hoverEvent(e, true) }} onMouseLeave={(e) => { hoverEvent(e, false) }} onClick={optionSelectedEvent}>
                                            <BuildingSVG height={svgSize} width={svgSize} stroke={windowWidth <= 1200 ? (selected["buildingIcon"] || hovered["buildingIcon"] ? "#266CA0" : "black") : (selected["buildingIcon"] || hovered["buildingIcon"] ? "#266CA0" : "white")} className="mr-1" title="Insurance Information"></BuildingSVG>
                                            <p className="option-text buildingIcon">Insurance Information</p>
                                        </div>
                                    </Link>
                                }

                                {settingStore.getSetting().showInsurance &&
                                    <Link to="/allappointments" id="InsBadge" title="All Appointments" className="option-group-link" onClick={() => clientStore.setSelectedTab(getIdxInsurance())}>
                                        <div className="option-group" id="appointmentsIcon" onClick={optionSelectedEvent} onMouseEnter={(e) => { hoverEvent(e, true) }} onMouseLeave={(e) => { hoverEvent(e, false) }}>
                                            <AppointmentsSVG height={svgSize} width={svgSize} stroke={windowWidth <= 1200 ? (selected["appointmentsIcon"] || hovered["appointmentsIcon"] ? "#266CA0" : "black") : (selected["appointmentsIcon"] || hovered["appointmentsIcon"] ? "#266CA0" : "white")} className="mr-1" title="All Appointments"></AppointmentsSVG>
                                            <p className="option-text appointmentsIcon">Appointments</p>
                                        </div>
                                    </Link>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="avatar" onClick={avatarClick} ref={avatarDiv}>

                        <div className='avatar'>
                            {clientStore.avatarImage && <img className='profile-image' src={clientStore.avatarImage} alt='' title='Avatar' />}
                            <Popup show={show} anchor={avatarDiv.current} className="popup mt-2 ml-1">
                                <Menu vertical={true} onSelect={menuSelect} className="profileMenu">
                                    <MenuItem text="Sign Out" svgIcon={logoutIcon} />
                                    <MenuItem text="Change Password" svgIcon={passwordIcon} />
                                    {settingStore.settings?.enableAvatarUpload && <MenuItem text="Update Avatar" svgIcon={cameraIcon} disabled={!settingStore.getSetting().enableAvatarUpload} />}
                                </Menu>
                            </Popup>
                        </div>

                        <span className="welcome-message">
                            Welcome <span id="clientName">{user.profile.firstName ?? ""} {user.profile.lastName ?? ""}<span className="material-symbols-outlined expand-icon">expand_more</span></span>
                        </span>
                    </div>
                </div>
            </Tooltip>
            {clientStore.showPasswordDialog &&
                <ChangePassword />
            }
            {clientStore.showAvatarDialog &&
                <AvatarDialog />
            }

            <div className="last-login-section">
                <Col sm="12" className="lastlogin">
                    <div className="current-page">
                        <span>PIMSY | {pageTitle}</span>
                    </div>
                    <div className="provider web-view">{devTitle}</div>
                    {selected["homeIcon"] && <div className="last-login-info">
                        <span className="pr-1 gray1 web-view-lastlogin">Your last login was on</span>
                        <span className="time_bold web-view-lastlogin"><LongDate date={currentUser.lastLogin}></LongDate> |</span>
                        <Link to="/activity" className="activity-link ml-1">View All Activity →</Link>
                    </div>}
                </Col>
            </div>
            <Row className="mobile-view-row">
                <div className="provider mobile-view-provider">{title}</div>
                {selected["homeIcon"] && <div className="mobile-view">
                    <div>
                        <span className="pr-1 gray1">Your last login was on</span>
                        <span className='time_bold'><LongDate date={currentUser.lastLogin}></LongDate></span>
                    </div>
                    <Link to="/activity" className="activity-link-mobile ml-1">View All Activity →</Link>
                </div>}
            </Row>
        </>
    )
});

export default Header;