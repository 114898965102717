import { authenticationService } from '../services';

export const authHeader = (): Headers =>  {
    // return authorization header with jwt token
    const headers = new Headers();

    const currentUser = authenticationService.currentUserValue;
    if (currentUser && currentUser.token) {
        headers.append('Authorization', `Bearer ${currentUser.token}`);
    }
    return headers;
}