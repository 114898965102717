
import { Basics, ClientBasics, SaveBasicsResponse } from '../models/ClientBasics';
import { Details, ClientDetails, SaveDetailsResponse } from '../models/ClientDetails';
import { ClientContacts, Contact, SaveContactsResponse } from '../models/ClientContacts';
import { Insurance, ClientInsurance, SaveInsuranceResponse } from '../models/ClientInsurance';
import { NoticationTypes } from '../models/enums';
import { ClientStore } from '../stores/clientStore';

import { LayoutStore } from '../stores/layoutStore';
import { http } from './http';
import { AvatarRequest, AvatarResponse } from '../models/ClientAvatar';
import { ProfileMessagingConsent } from '../models/User';
import { MessagingPref } from '../models/MessagingPreferences';

let clientStore: ClientStore;
let layoutStore: LayoutStore;

const fetchClientBasics = async (store: ClientStore, loStore: LayoutStore) => {
    clientStore = store;
    layoutStore = loStore;

    store.setShowClientSpinner(true);

    let clientBasics: ClientBasics = {
        success: false,
        friendlyMessage: '',
        basics: {
            firstName: '',
            lastName: '',
            middleName: '',
            aka: '',
            maidenName: '',
            dob: undefined,
            email: '',
            genderID: { text: '', value: '' },
            homePhone: '',
            cell: '',
            workPhone: '',
            fax: '',
            address1: '',
            address2: '',
            ssn: '',
            city: '',
            stateAbbrev: { text: '', value: '' },
            zip: '',
            clientID: 0,
            contactMethodID: { text: '', value: '' },
            consentToReceiveMessages: '',
            useClientInfoForMessaging: false,
            messagingContact: {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                cell: '',
                address1: '',
                address2: '',
                city: '',
                stateAbbrev: { text: '', value: '' },
                zip: '',
                relationShip: { text: '', value: '' },
                type: 'Messaging',
                contactID: 0,
                contactTypeId: 0,
                addressId: 0,
                stateId: 0
            }
        }
    }

    const response = await http<undefined, ClientBasics>({
        path: 'profile/basics'
    });
    if (response.ok && response.parsedBody) {
        if (response.parsedBody.success) {
            clientBasics = response.parsedBody;
        }
        else {
            layoutStore.setShowNotification(true, NoticationTypes.error, response.parsedBody.friendlyMessage);
        }
    } else {
        layoutStore.setShowNotification(true, NoticationTypes.error, `API Error: ${response.statusText}`);
    }

    store.setClientBasics(clientBasics);
    store.setShowClientSpinner(false);

    return response.ok;
}

const fetchClientInsurance = async (store: ClientStore, loStore: LayoutStore) => {
    clientStore = store;
    layoutStore = loStore;

    store.setShowClientSpinner(true);

    let clientInsurance: ClientInsurance = {
        success: false,
        friendlyMessage: '',
        insurance: {
            clientID: 0,
            companyPrimary: {text: '', value: ''},
            groupintPrimary: '',
            subscriberIDPrimary: '',
            dateIssuedPrimary: undefined,
            expireDatePrimary: undefined,
            deductiblePrimary: 0,
            coPayPrimary: 0,
            relationshipPrimary: {text: '', value: ''},
            firstNameInsured: '',
            lastNameInsured: '',
            phoneNoInsured: '',
            dobInsured: undefined,
            companySecondary: {text: '', value: ''},
            groupintSecondary: '',
            subscriberIDSecondary: '',
            dateIssuedSecondary: undefined,
            expireDateSecondary: undefined,
            deductibleSecondary: 0,
            coPaySecondary: 0,
            relationshipSecondary: {text: '', value: ''},
            firstNameSecondaryInsured: '',
            lastNameSecondaryInsured: '',
            phoneNoSecondaryInsured: '',
            dobSecondaryInsured: undefined,
            contactId: undefined,
            secondaryContactId: undefined
        }
    }
    store.setClientInsurance(clientInsurance);

    const response = await http<undefined, ClientInsurance>({
        path: 'insurance/getInsurance'
    });
    if (response.ok && response.parsedBody) {
        if (response.parsedBody.success) {
            clientInsurance = response.parsedBody;
        }
        else {
            layoutStore.setShowNotification(true, NoticationTypes.error, response.parsedBody.friendlyMessage);
        }
    } else {
        layoutStore.setShowNotification(true, NoticationTypes.error, `API Error: ${response.statusText}`);
    }

    store.setClientInsurance(clientInsurance);
    store.setShowClientSpinner(false);

    return response.ok;

}

const fetchClientContacts = async (store: ClientStore, loStore: LayoutStore) => {
    clientStore = store;
    layoutStore = loStore;

    store.setShowClientSpinner(true);

    let clientContacts: ClientContacts = {
        success: true, //false,
        friendlyMessage: '',
        contacts: [
            {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                cell: '',
                address1: '',
                address2: '',
                city: '',
                stateAbbrev: {text: '', value: ''},
                zip: '',
                contactID: 0,
                type: '',
                relationShip: { text: '', value: ''},
                contactTypeId: 0,
                addressId: 0,
                stateId: 0
            },
            {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                cell: '',
                address1: '',
                address2: '',
                city: '',
                stateAbbrev: {text: '', value: ''},
                zip: '',
                relationShip: {text: '', value: ''},
                type: '',
                contactID: 0,
                contactTypeId: 0,
                addressId: 0,
                stateId: 0
            },
            {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                cell: '',
                address1: '',
                address2: '',
                city: '',
                stateAbbrev: {text: '', value: ''},
                zip: '',
                relationShip: {text: '', value: ''},
                type: '',
                contactID: 0,
                contactTypeId: 0,
                addressId: 0,
                stateId: 0
            },
            {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                cell: '',
                address1: '',
                address2: '',
                city: '',
                stateAbbrev: {text: '', value: ''},
                zip: '',
                relationShip: {text: '', value: ''},
                type: '',
                contactID: 0,
                contactTypeId: 0,
                addressId: 0,
                stateId: 0
            }
        ]
    }


    const response = await http<undefined, ClientContacts>({
        path: `contacts/all`
    });
    if (response.ok && response.parsedBody) {
        if (response.parsedBody.success) {
            clientContacts = response.parsedBody;
        }
        else {
            layoutStore.setShowNotification(true, NoticationTypes.error, response.parsedBody.friendlyMessage);
        }
    } else {
        layoutStore.setShowNotification(true, NoticationTypes.error, `API Error: ${response.statusText}`);
    }

    store.setClientContacts(clientContacts);
    store.setShowClientSpinner(false);

    return response.ok;
}

const saveBasics = async (clientBasics: Basics) => {
    clientStore.setShowClientSpinner(true);

    clientBasics.fax = stripPhoneMask(clientBasics.fax);
    clientBasics.cell = stripPhoneMask(clientBasics.cell);
    clientBasics.homePhone = stripPhoneMask(clientBasics.homePhone);
    clientBasics.workPhone = stripPhoneMask(clientBasics.workPhone);
    clientBasics.ssn = stripSSNMask(clientBasics.ssn);

    if (clientBasics.contactMethodID.value === '0') {
        clientBasics.consentToReceiveMessages = 'no';
    }

    clientStore.setBasics(clientBasics);

    const response = await http<Basics, SaveBasicsResponse>({
        method: "POST",
        body: clientBasics,
        path: `profile/basics`
    });
    clientStore.setShowClientSpinner(false);

    if (response.ok && response.parsedBody) {
        if (response.parsedBody.success) {
            layoutStore.setShowNotification(true, NoticationTypes.success, "Basic Information Saved!");
            return response.parsedBody;
        } else {
            layoutStore.setShowNotification(true, NoticationTypes.error, response.parsedBody.friendlyMessage);
        }
    } else {
        layoutStore.setShowNotification(true, NoticationTypes.error, `API Error: ${response.statusText}`);
    }
}

const saveContacts = async (clientContact: Contact) => {
    clientStore.setShowClientSpinner(true);

    clientContact.phone = stripPhoneMask(clientContact.phone);
    clientContact.cell = stripPhoneMask(clientContact.cell);

    clientStore.setContact(clientContact)

    const response = await http<Contact, SaveContactsResponse>({
        method: "POST",
        body: clientContact,
        path: `contacts/save`
    });
    clientStore.setShowClientSpinner(false);

    if (response.ok && response.parsedBody) {
        if (response.parsedBody.success) {
            layoutStore.setShowNotification(true, NoticationTypes.success, "Contact Information Saved!");

            clientContact.contactID = response.parsedBody.identity.contactId;
            clientContact.addressId = response.parsedBody.identity.addressId;
            clientContact.stateId = response.parsedBody.identity.stateId;
            clientStore.setContact(clientContact);

            return response.parsedBody;
        } else {
            layoutStore.setShowNotification(true, NoticationTypes.error, response.parsedBody.friendlyMessage);
        }
    } else {
        layoutStore.setShowNotification(true, NoticationTypes.error, `API Error: ${response.statusText}`);
    }
}

const saveInsurance = async (clientInsurance: Insurance) => {
    clientStore.setShowClientSpinner(true);

    if (!clientInsurance.coPaySecondary) clientInsurance.coPaySecondary = 0;
    if (!clientInsurance.deductibleSecondary) clientInsurance.deductibleSecondary = 0;
    if (!clientInsurance.coPayPrimary) clientInsurance.coPayPrimary = 0;
    if (!clientInsurance.deductiblePrimary) clientInsurance.deductiblePrimary = 0;

    const response = await http<Insurance, SaveInsuranceResponse>({
        method: "POST",
        body: clientInsurance,
        path: `insurance/save`
    });
    clientStore.setShowClientSpinner(false);

    if (response.ok && response.parsedBody) {
        if (response.parsedBody.success) {
            if (response.parsedBody.primaryContactId) {
                clientInsurance.contactId = response.parsedBody.primaryContactId;
            }
            if (response.parsedBody.secondaryContactId) {
                clientInsurance.secondaryContactId = response.parsedBody.secondaryContactId;
            }

            layoutStore.setShowNotification(true, NoticationTypes.success, "Insurance Information Saved!");
            return response.parsedBody;
        } else {
            layoutStore.setShowNotification(true, NoticationTypes.error, response.parsedBody.friendlyMessage);
        }
    } else {
        layoutStore.setShowNotification(true, NoticationTypes.error, `API Error: ${response.statusText}`);
    }

    clientStore.setInsurance(clientInsurance);
}

const fetchClientDetails = async (store: ClientStore, loStore: LayoutStore) => {
    if (!clientStore) clientStore = store;
    if (!layoutStore) layoutStore = loStore;

    store.setShowClientSpinner(true);

    let clientDetails: ClientDetails = {
        success: false,
        friendlyMessage: '',
        details: {
            clientID: 0,
            raceID: {text: '', value: ''},
            ethnicityID: {text: '', value: ''},
            languageID: {text: '', value: ''},
            isPregnant: false,
            isEnglishPro: false,
            livingArrangementID: {text: '', value: ''},
            residenceTypeID: {text: '', value: ''},
            adultsInHouseHold: 0,
            childrenInHouseHold: 0,
            maritalStatusID: {text: '', value: ''},
            annualIncome: 0,
            employmentStatusID: {text: '', value: ''},
            disabilityID: {text: '', value: ''},
            smokingStatusID: {text: '', value: ''},
            isVeteran: false,
            schoolID: {text: '', value: ''},
            gradeID: {text: '', value: ''},
            targetPopulationID: {text: '', value: ''}
        }
    };

    const response = await http<undefined, ClientDetails>({
        path: 'profile/details'
    });
    if (response.ok && response.parsedBody) {
        if (response.parsedBody.success) {
            clientDetails = response.parsedBody;
        }
        else {
            layoutStore.setShowNotification(true, NoticationTypes.error, response.parsedBody.friendlyMessage);
        }
    } else {
        layoutStore.setShowNotification(true, NoticationTypes.error, `API Error: ${response.statusText}`);
    }

    store.setClientDetails(clientDetails);
    store.setShowClientSpinner(false);

    return response.ok
}

const saveDetails = async (clientDetails: Details) => {
    clientStore.setShowClientSpinner(true);
    clientStore.setDetails(clientDetails);

    const response = await http<Details, SaveDetailsResponse>({
        method: "POST",
        body: clientDetails,
        path: `profile/details`
    });
    clientStore.setShowClientSpinner(false);

    if (response.ok && response.parsedBody) {
        if (response.parsedBody.success) {
            layoutStore.setShowNotification(true, NoticationTypes.success, "Detail Information Saved!");
            return response.parsedBody;
        } else {
            layoutStore.setShowNotification(true, NoticationTypes.error, response.parsedBody.friendlyMessage);
        }
    } else {
        layoutStore.setShowNotification(true, NoticationTypes.error, `API Error: ${response.statusText}`);
    }
}

const saveAvatar = async (store: ClientStore, imageBase64: string) => {
    const avatarRequest: AvatarRequest ={
        imageSrc: imageBase64
    };

    const response = await http<AvatarRequest, AvatarResponse>({
        method: "POST",
        body: avatarRequest,
        path: `profile/analyzeAvatar`
    });

    if (response.ok && response.parsedBody) {
        if (response.parsedBody.success) {
            store.setAvatarImage(response.parsedBody.avatarBase64);
            return true;
        }
    }

    return false;
}

const saveMessagingConsent = async(store: ClientStore, loStore: LayoutStore, messagePref: MessagingPref, contactID: number, useMessageContact: boolean) => {

    const saveConsent: ProfileMessagingConsent = {
        consent: messagePref.consentToReceiveMessages === 'yes' ? 1 : 0,
        contactMethodID: parseInt(messagePref.contactMethodID.value),
        messagingEmail: messagePref.email,
        messagingPhone: messagePref.homePhone,
        messagingCell: messagePref.cell,
        firstName: messagePref.firstName,
        lastName: messagePref.lastName,
        saveToMessaging: useMessageContact,
        contactID: contactID
    }
    store.setShowClientSpinner(true);

    const response = await http<ProfileMessagingConsent, undefined>({
        method: "POST",
        body: saveConsent,
        path: `profile/messagingConsent`
    });

    store.setShowClientSpinner(false);

    if (response.ok) {
        store.setShowMessagingConsent(false);
        loStore.setShowNotification(true, NoticationTypes.success, "Messaging Preferences Saved!");
        return true;
    }
    else {
        loStore.setShowNotification(true, NoticationTypes.error, "Failed to save Messaging Preferences");
    }

    return false;
}


const saveMessagingConsentOnly = async(store: ClientStore, consent: number) => {

    const saveConsent: ProfileMessagingConsent = {
        consent: consent,
        contactMethodID: 0,
        messagingEmail: '',
        messagingPhone: '',
        messagingCell: '',
        firstName: '',
        lastName: '',
        saveToMessaging: false,
        contactID: 0
    }

    const response = await http<ProfileMessagingConsent, undefined>({
        method: "POST",
        body: saveConsent,
        path: `profile/messagingConsentOnly`
    });

    if (response.ok) {
        store.setShowMessagingConsent(false);
        return true;
    }

    return false;
}

const stripPhoneMask = (phone: string) => {
    if (phone === "(___) ___-____") {
        return "";
    }
    return phone;
};

const stripSSNMask = (ssn: string) => {
    if (ssn === "___-__-____") {
        return "";
    }
    return ssn;
};

export const clientService = {
    fetchClientBasics,
    saveBasics,
    fetchClientDetails,
    saveDetails,
    fetchClientContacts,
    saveContacts,
    fetchClientInsurance,
    saveInsurance,
    saveAvatar,
    saveMessagingConsent,
    saveMessagingConsentOnly
}