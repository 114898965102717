import { makeAutoObservable } from 'mobx'
import { ProviderSetting } from '../models/ProviderSetting';

export class SettingStore {

    settings: ProviderSetting | undefined;
    logo: string = "/images/PIMSYWithSun_sm.png";
    logoLoaded: boolean = false;
    messageCount: number = 0;
    settingsLoaded: boolean = false;

    setSettingsLoaded = () => {
        this.settingsLoaded = true;
    };

    setSettings = (settings: ProviderSetting) => {
        this.settings = settings;
    }

    getSetting = (): ProviderSetting => {
        if (this.settings) {
            return this.settings
        }
        const noSetting: ProviderSetting = {
            title: '',
            theme: '',
            showInsurance: false,
            showBasics: false,
            showDetails: false,
            showMessaging: false,
            showTotalDue: false,
            showAppointments: false,
            showGender: false,
            showSSN: false,
            canSaveBasics: false,
            canSaveDetails: false,
            targetPopulationLabel: '',
            canSaveResponsibleParty: false,
            canSaveEmergGuard: false,
            instructions: '',
            paymentGateway: '',
            paymentMode: '',
            hidePayments: true,
            showBillingHistory: false,
            gpKey: '',
            forgotPassword: '',
            billingContactEmail: '',
            passwordRegEx: '',
            passwordRegExDesc: '',
            hideManualPayment: true,
            canadian: false,
            showSelfScheduler: false,
            enableAvatarUpload: false,
            allowTelehealth: false,
            pendingAppointment: false,
            allowClientsToScheduleApt: false,

            profileRules: {
                ccdCreate: false,
                ccdView: false,
                ccdAutomationFlag: false,
                ccdFhir: false,
                secondaryInsuranceView: false
            },

            siteRules: {
                taskView: false
            }
        };

        return noSetting;
    }

    setLogoLoaded = () => {
        this.logoLoaded = true;
    };
    setLogo = (data: string) => {
        this.logo = data;
    };
    setMessageCount = (count: number) => {
        this.messageCount = count;
    };

    constructor() {
        makeAutoObservable(this)
    }
}
