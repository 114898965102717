import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import '../../assets/styles/appschedulerheader.scss';
import { observer } from 'mobx-react';
import { useSettingStore } from '../../hooks/hooks';
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useHistory } from 'react-router-dom';
import headingimg from '../../assets/images/headingimg.png';
import homeimg from '../../assets/images/innerhome.png';
import { ButtonType, CustomButton } from '../client/appointmentscheduler/CustomButton';

interface Props {
    title?: string;
}

export const Header: FC<Props> = observer(({ title }) => {
    const settingStore = useSettingStore();
    const history = useHistory();

    return (
        <>
            <Tooltip anchorElement="target" position="bottom">
                <div className="appscheduler-header">
                    <div className="logo">
                        <Row className='heading'>
                            <Col className="text-left">
                                {settingStore.logoLoaded &&
                                    <Link to="/"><img className='topLogo' src={settingStore.logo} alt='Pimsy' id="LogoLink" title="Go to Home"></img></Link>
                                }

                                <Col>
                                    <span className='headertext ml-2'>Powered by</span><img className='buttomimage' src={headingimg} alt='appoinmentbook' />
                                </Col>
                            </Col>
                            <Col>
                            <div className="provider">{title}</div>
                            </Col>

                            <Col className="text-right">

                                <div className="controls">
                                    <CustomButton classNames='buttonheader' type={ButtonType.BUTTON} buttonText='Go Back to Home' onClick={() => { history.push('/') }}></CustomButton>
                                    <div className='outerimage'><img className='homeimage' src={homeimg} alt='home' onClick={() => { history.push('/') }}/></div>
                                    <span id="clientName"></span>
                                </div>
                            </Col>
                        </Row>
                    </div>

                </div>
            </Tooltip>
        </>
    )
});

export default Header;