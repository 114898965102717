export enum NoticationTypes {
    error,
    success,
    warning,
    info,
    none
}

export enum PickListType {
    Disability,
    EmploymentStatus,
    Ethnicity,
    Gender,
    Grade,
    Relationship,
    Language,
    LivingArrangement,
    MaritalStatus,
    Race,
    Residence,
    School,
    SmokingStatus,
    TargetPopulation,
    InsuranceCo
}

export enum ContactType {
    Emergency = "Emergency",
    LegalGuardian = "Legal Guardian",
    ResponsibleParty = "Responsible Party",
    PreferredPhysician = "Preferred Physician"
}

export enum ControlType {
    Date = -1,
    YesNo = -2,
    FreeTextLong = -3,
    Integer = -4,
    NumberWithDecimal = -5,
    AgreeDisagree = -6,
    ScaleOf1to10 = -7,
    DateWithTime = -8,
    PickList = -9,
    NeverAlways = -10,
    //Image = -11,
    SectionHeader = -12,
    SignOffYN = -13,
    FreeTextShort = -14,
    SignaturePad = -15,
    MildSevere = -16,
    FillInBlank = -17,
    //ExecuteButton = -18,
    PickListCheckBox = -22,
    PickListRadioButton = -23,

    // artifical type for the isFormComplete flag
    FormComplete = -99
}

export enum SaveAppointmentStatus {
    NoOp  = 0,
    InProcess = 1,
    Success = 2,
    Failed = 3
}
