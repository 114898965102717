import { makeAutoObservable } from 'mobx'
import { AlertMessage, SentMessage } from '../models/AlertMessage'


export class AlertMessageStore {

    alertMessages: AlertMessage[] = [];
    sentMessages: SentMessage[] = [];

    showMessageSpinner: boolean = false;

    selectTab: number = 0;

    setAlertMessages = (messages: AlertMessage[], sentMessages: SentMessage[]) => {
        this.alertMessages = messages;
        this.sentMessages = sentMessages;
    };

    setAlertMessageAsRead = (id: number) => {
        this.alertMessages.find(m => m.id === id)!.acknowledged = true;
    };
    setShowMessageSpinner = (flag: boolean) => {
        this.showMessageSpinner = flag;
    };

    setSelectedTab = (tab: number) => {
        this.selectTab = tab;
    }

    constructor() {
        makeAutoObservable(this)
    }
}
