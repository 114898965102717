import React, {FC, useState} from 'react';
import { authenticationService } from '../../services';
import { Link } from 'react-router-dom';
import { useSettingStore, useClientStore } from '../../hooks/hooks';
import { observer } from 'mobx-react';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from '@progress/kendo-react-buttons';
import { FormRequiredValidator } from '../shared/FormFieldComponents';
import { FormInput } from '../shared/KendoFormComponents';
import ChangePassword from './ChangePassword';
import { RouteComponentProps} from 'react-router-dom';

interface RouteParms {
    id: string;
}

export const Login: FC<RouteComponentProps<RouteParms>> = observer(({match}) => {
    const settingStore = useSettingStore();
    const clientStore = useClientStore();

    const [showChange , setShowChange ] = useState(false);
    const [showFailure , setShowFailure ] = useState(false);
    const [currentPassword , setCurrentPassword ] = useState("");

    const handleSubmit = async (data: any, event: any) => {
        try {
            const user = await authenticationService.login(data.username, data.password);
            if (user) {
                localStorage.removeItem("beforeunloadTS");

                if (user.changePassword) {
                    setCurrentPassword(data.password);
                    setShowChange(true);
                    return;
                }
                else {
                    setShowFailure(false);

                    if (match.path.indexOf('forms') > 0) {
                        sessionStorage.setItem("route-to-forms", match.params.id ?? '0');
                    }

                    if (user.profile.avatar) {
                        clientStore.setAvatarImage(user.profile.avatar);
                    }

                    window.location.href = "/";
                    return;
                }
            }
            setShowFailure(true);
        } catch (error) {
            setShowFailure(true);
        }
    }

    return (
        <div className='login-bg'>
            <div className="login">
                <div className='text-center'>
                    { settingStore.logoLoaded &&
                        <img className="logo" src={settingStore.logo} alt=""/>
                    }
                </div>
                <Form
                    onSubmit={handleSubmit}
                    render = {(formProps: { allowSubmit: any; }) => (
                        <FormElement>
                            <Field label = "Username" name = "username" id = "username"
                                component = {FormInput} validator={FormRequiredValidator} />
                            <Field label = "Password" name = "password" id = "password" type = "password"
                                component = {FormInput} validator={FormRequiredValidator} />

                            <div className='mb-3'>
                                <Link to="/forgot" className='forgot_password'>Forgot your Password?</Link>
                            </div>
                            <Button type="submit" className='primary-button'
                                themeColor={"primary"}
                                disabled={!formProps.allowSubmit}>
                                Sign In
                            </Button>
                        </FormElement>
                    )}
                />
                { showFailure && <p className='failure'>Username or Password not found.</p> }
                { showChange &&
                    <ChangePassword currentPassword={currentPassword}/>
                }
            </div>
        </div>
    );
});

export default Login;