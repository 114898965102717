import  React, { FC } from 'react';
import { Container } from 'reactstrap';
import { Notification } from '@progress/kendo-react-notification';
import { ClientUser } from '../models/User';
import Footer from './footer/Footer';
import Header from './header/Header';
import { Fade } from '@progress/kendo-react-animation';
import { useLayoutStore } from '../hooks/hooks';
import { observer } from 'mobx-react';
import { NoticationTypes } from '../models/enums';

interface Props {
  user: ClientUser;
}

export const Layout: FC<Props> = observer(({user, children}) => {
    const layoutStore = useLayoutStore();

    return (
      <div className="layout">
        { user &&
          <Header user={user}/>
        }

        {/* <NavMenu /> */}
        <Container fluid>

        <Fade enter={true} exit={true}>
          { layoutStore.showNotification &&
            <>
              { layoutStore.notificationType === NoticationTypes.error &&
                <Notification
                  type={{ style: 'error', icon: true }}
                  closable={false}
                  onClose={() => layoutStore.hideNotification()}
                >
                  <span>{layoutStore.notificationMessage}</span>
                </Notification>
              }
              { layoutStore.notificationType === NoticationTypes.success &&
                <Notification
                  type={{ style: 'success', icon: true }}
                  closable={false}
                  onClose={() => layoutStore.hideNotification()}
                >
                  <span>{layoutStore.notificationMessage}</span>
                </Notification>
              }
            </>
          }
        </Fade>

        {children}
        </Container>

        { user &&
          <Footer/>
        }
      </div>
    )
  }
);
