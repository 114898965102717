import { makeAutoObservable } from 'mobx'
import { ClientCreditCard, CreditCard, Payment, PaymentsList, Statement, StatementsList } from '../models/Payments';
export class PaymentStore {

    statementsList: StatementsList = {
        success: false,
        friendlyMessage: '',
        statements: []
    };

    paymentsList: PaymentsList = {
        success: false,
        friendlyMessage: '',
        payments: []
    };

    clientCreditCard: ClientCreditCard = {
        success: false,
        friendlyMessage: '',
        creditCards: []
    };

    showPaymentSpinner: boolean = false;
    selectTab: number = 0;

    selectedStatement: undefined | Statement = {
        rowNum: 0,
        statementType: '',
        performedByFullName: '',
        serviceDate: '',
        billingCodeDescription: '',
        totalDue: 0,
        amountInvoiced: 0,
        noteID: 0,
        noteNumber: '',
        salid: 0,
        salNumber: '',
        initialInvoicedDate: ''
    };

    selectedCreditCard: CreditCard = {
        cccid: 0,
        creditCardFriendlyName: ''
    }

    ccNumber: string = '';
    ccNumberValid: boolean = false;
    ccExpiry: string = '';
    ccExpiryValid: boolean = false;
    ccCVV: string = '';
    ccCVVValid: boolean = false;
    saveCardToProfile: boolean = true;
    saveCardOnly: boolean = false;
    isManualPayment: boolean = false;
    isSalPayment: boolean = false;

    paymentAmount: number = 0;
    balanceDue: number = 0;

    showFailPayment: boolean = false;
    failPaymentMessage: string = '';
    noteBillingId: number = 0;
    paidSalId: number = 0;
    paidNoteId: number = 0;

    reportToken: string = '';

    setFailPaymentMessage = (failed: boolean, msg: string, noteBillingId: number, salId: number, noteId: number) => {
        this.showFailPayment = failed;
        this.failPaymentMessage = msg;
        this.noteBillingId = noteBillingId;
        this.paidSalId = salId;
        this.paidNoteId = noteId;

    };

    setReportToken = (token: string) => {
        this.reportToken = token;
    }

    setSelectedPayment = (payment: Payment) => {
        this.noteBillingId = payment.noteBillingID;
        this.paidNoteId = payment.noteID;
        this.paidSalId = payment.salid;
    }

    setBalanceDue = (balance: number) => {
        this.balanceDue = balance;
    };

    setStatementsList = (statements: StatementsList) => {
        this.statementsList = statements;
    };

    setPaymentsList = (payments: PaymentsList) => {
        this.paymentsList = payments;
    };

    setClientCreditCard = (creditCard: ClientCreditCard) => {
        this.clientCreditCard = creditCard;
    };

    setShowPaymentSpinner = (flag: boolean) => {
        this.showPaymentSpinner = flag;
    };

    setSelectedTab = (tab: number) => {
        this.selectTab = tab;
    };

    setSelectedStatment = (item: Statement) => {
        this.selectedStatement = item;
    };

    setSelectedCreditCard = (item: CreditCard) => {
        this.selectedCreditCard = item;
    };

    clearSelectedStatementRowNum = () => {
        if (!this.isSalPayment) {
            this.selectedStatement = undefined;
        }
    };

    setCCNumber = (num: string, valid: boolean) => {
        this.ccNumber = num;
        this.ccNumberValid = valid;
    };
    setCCExpiry = (ep: string, valid: boolean) => {
        this.ccExpiry = ep;
        this.ccExpiryValid = valid;
    };
    setCCCVV = (cvv: string, valid: boolean) => {
        this.ccCVV = cvv;
        this.ccCVVValid = valid;
    };
    setPaymentAmount = (amount: number) => {
        this.paymentAmount = amount;
    }
    setSaveCardToProfile = (flag: boolean) => {
        this.saveCardToProfile = flag;
    };
    setSaveCardOnly = (flag: boolean) => {
        this.saveCardOnly = flag;
    };

    setManualPayment = (flag: boolean) => {
        this.selectedStatement = {
            rowNum: 0,
            statementType: '',
            performedByFullName: '',
            serviceDate: '',
            billingCodeDescription: '',
            totalDue: 0,
            amountInvoiced: 0,
            noteID: 0,
            noteNumber: '',
            salid: 0,
            salNumber: '',
            initialInvoicedDate: ''
        };

        this.isManualPayment = flag;
    };

    setSalPayment = (salId: number, noteID: number, performedBy: string, serviceDate: string, serviceDesc: string, amount: number, salNumber: string, noteNumber: string) => {
        this.selectedStatement = {
            rowNum: 0,
            statementType: '',
            performedByFullName: performedBy,
            serviceDate: serviceDate,
            billingCodeDescription: serviceDesc,
            totalDue: amount,
            amountInvoiced: 0,
            noteID: noteID,
            noteNumber: noteNumber,
            salid: salId,
            salNumber: salNumber,
            initialInvoicedDate: ''
        };

        this.paymentAmount = amount;
        this.isSalPayment = true;
    }
    resetIsSalPayment = () => {
        this.isSalPayment = false;
    }

    getIsValidCC = () => {
        return this.ccNumberValid && this.ccExpiryValid &&  this.ccCVVValid;
    };

    clearCreditCard = () => {
        this.ccNumber = "";
        this.ccExpiry = "";
        this.ccCVV = "";
    };

    constructor() {
        makeAutoObservable(this)
    };
}
