import { useContext } from 'react'
import { MobXProviderContext } from 'mobx-react'
import { AlertMessageStore } from '../stores/alertMessageStore'
import { LayoutStore } from '../stores/layoutStore'
import { SettingStore } from '../stores/settingStore'
import { AppointmentStore } from '../stores/appointmentStore'
import { ActivityStore } from '../stores/activityStore'
import { ClientStore } from '../stores/clientStore'
import { PickListStore } from '../stores/pickListStore'
import { LibraryStore } from '../stores/libraryStore'
import { PaymentStore } from '../stores/paymentStore'
import { PasswordStore } from '../stores/passwordStore'
import { SpecialtyStore } from '../stores/specialtyStore'
import { ConfirmStore } from '../stores/confirmStore'
import { GuidelineStore } from "../stores/guidelineStore";
import { PractitionerStore } from "../stores/practitionerStore";
import { BasicDetailsStore } from "../stores/basicDetailsStore";
import { SurveyStore } from "../stores/surveyStore";

export function useStores () {
  return useContext(MobXProviderContext)
}

export function useAlertMessageStore(): AlertMessageStore  {
  const { alertMessageStore } = useStores()
  return alertMessageStore;
}

export function useLayoutStore(): LayoutStore  {
  const { layoutStore } = useStores()
  return layoutStore;
}

export function useLibraryStore(): LibraryStore  {
  const { libraryStore } = useStores()
  return libraryStore;
}

export function useSettingStore(): SettingStore  {
  const { settingStore } = useStores()
  return settingStore;
}

export function useAppointmentStore(): AppointmentStore  {
  const { appointmentStore } = useStores()
  return appointmentStore;
}

export function useActivityStore(): ActivityStore  {
  const { activityStore } = useStores()
  return activityStore;
}

export function useClientStore(): ClientStore  {
  const { clientStore } = useStores()
  return clientStore;
}

export function usePickListStore(): PickListStore  {
  const { pickListStore } = useStores()
  return pickListStore;
}

export function usePaymentStore(): PaymentStore  {
  const { paymentStore } = useStores()
  return paymentStore;
}

export function usePasswordStore(): PasswordStore  {
  const { passwordStore } = useStores()
  return passwordStore;
}

export function useSpecialtyStore(): SpecialtyStore  {
  const { specialtyStore } = useStores()
  return specialtyStore;
}

export function useConfirmStore(): ConfirmStore  {
  const { confirmStore } = useStores()
  return confirmStore;
}

export function usePractitionerStore(): PractitionerStore  {
  const { practitionerStore } = useStores()
  return practitionerStore;
}

export function useGuidelineStore(): GuidelineStore  {
  const { guidelineStore } = useStores()
  return guidelineStore;
}

export function useBasicDetailsStore(): BasicDetailsStore  {
  const { basicDetailsStore } = useStores()
  return basicDetailsStore;
}

export function useSurveyStore(): SurveyStore  {
  const { surveyStore } = useStores()
  return surveyStore;
}