import { makeAutoObservable } from 'mobx';

export class SurveyStore {

    surveyCurrentIndex : number= 0;
    itemLength : number = 0;

    setSurveyCurrentIndex = (surveyCurrentIndex:number,itemLength:number) => {
        this.surveyCurrentIndex=surveyCurrentIndex;
        this.itemLength=itemLength;
    }

    surveyCompleted : boolean = false;
    surveyShowMessage: boolean = false;

    setSurveyCompleted = (surveyCompleted:boolean) => {
        this.surveyCompleted = surveyCompleted;
    }

    setSurveyShowMessage = (surveyShowMessage:boolean) => {
        this.surveyShowMessage = surveyShowMessage;
    }

    constructor() {
        makeAutoObservable(this)
    };
}