import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../helpers';
import appSettings from '../AppSettings.json';
import { ClientUser, ForgotResponseModel } from '../models/User';

const localUser = localStorage.getItem('currentUser');
const jsonUser = localUser ? JSON.parse(localUser) : null;
const currentUserSubject = new BehaviorSubject(jsonUser);

const login = async (username: string, password: string ): Promise<ClientUser> => {
    const request = new Request(`${appSettings.api_url}users/authenticate`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ username: username.trim(), password: password.trim(), hostname: window.location.hostname }),
        credentials: 'include'
      });

    return await fetch(request)
        .then(handleResponse)
        .then((user: ClientUser) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            user.hostname = window.location.hostname;
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);

            return user;
        });
};

const logout = (isLogout: boolean = false): void => {
    localStorage.clear();
    sessionStorage.clear();
    currentUserSubject.next(null);
    document.cookie = '.PimsyClient.Session=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    window.location.href="/login";
};

const forgot = async (email: string, name: string): Promise<ForgotResponseModel> => {
    const request = new Request(`${appSettings.api_url}users/forgotPassword`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ email, domain: window.location.hostname, name }),
      });
      return await fetch(request)
                .then(handleResponse)
                .then((model: ForgotResponseModel) => {
                    return model;
                });
}

const SSOLogin = async (key: string): Promise<ClientUser> => {
    if (!key) {
        window.location.href="/login";
    }
    const request = new Request(`${appSettings.api_url}users/ssoLogin`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ key, hostname: window.location.hostname }),
        credentials: 'include'
      });

    return await fetch(request)
        .then(handleResponse)
        .then((user: ClientUser) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            user.hostname = window.location.hostname;
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);

            window.location.href="/";
            return user;
        });
};

const resetNeedMessageConsent = () : void => {
    const user = currentUserSubject.value;
    user.needMessagingConsent = false;
    localStorage.setItem('currentUser', JSON.stringify(user));
    currentUserSubject.next(user);
}

export const authenticationService = {
    login,
    logout,
    forgot,
    SSOLogin,
    resetNeedMessageConsent,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue (): ClientUser { return currentUserSubject.value }
};