import React, { FC } from 'react';
import { Button, Card, CardImg, CardTitle } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { ClientUser } from '../../models/User';
import { useAlertMessageStore, useSettingStore } from '../../hooks/hooks';
import { observer } from 'mobx-react';
import message_icon from '../../assets/images/message_icon.png';

interface Props {
    user: ClientUser;
}

export const HomeMessage: FC<Props> = observer(({ user }) => {
    const history = useHistory();
    const alertMessageStore = useAlertMessageStore();
    const settingStore = useSettingStore();

    const gotoSendMessages = () => {
        alertMessageStore.setSelectedTab(1);
        history.push('/messages');
    }

    const gotoViewMessages = () => {
        alertMessageStore.setSelectedTab(0);
        history.push('/messages')
    }

    return (
        <div className="sections">
            <Card className='custom_card'>
                <div className="card_heading" >
                    <CardImg className='icons' src={message_icon} ></CardImg>
                    <CardTitle tag="h5">View and Send Messages</CardTitle>
                </div>
                <div className="status">
                    <CardTitle className='card_title' tag="p">You have {settingStore.messageCount > 0 ? settingStore.messageCount : 'no'} new message(s) </CardTitle>
                </div>

                <div className="instructions">

                    {user.profile.alertCount === 0 &&
                        <><i>Messages</i> from your provider may be sent from the system. To send a new message or view older messages, use the buttons below.</>
                    }
                    {user.profile.alertCount > 0 &&
                        <>You have  new Messages from the Pimsy Portal, and others. You can view and respond using the buttons below.</>
                    }
                </div>
                <div className='menu_buttons'>
                    <Button  size="sm" onClick={gotoSendMessages}> Send New Message</Button>
                    <Button  size="sm" onClick={gotoViewMessages}> View Messages</Button>
                </div>
            </Card>
        </div>
    )

});

export default HomeMessage;