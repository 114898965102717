
import { http } from './http';
import { AlertMessageStore } from '../stores/alertMessageStore';
import { LayoutStore } from '../stores/layoutStore'

import { AlertMessage, Alerts, SendToUsers, SendUserMessageResponse, SendUserMessageRequest, MessageTypeList, SentMessage } from '../models/AlertMessage';
import { authenticationService } from '../services';
import { dateToLocal } from '../helpers';
import { NoticationTypes } from '../models/enums';
import { ClientUser } from '../models/User';

let messageStore: AlertMessageStore;
let layoutStore: LayoutStore;

const fetchMessages = async (store: AlertMessageStore, loStore: LayoutStore) => {
    messageStore = store;
    layoutStore = loStore;
    let messages: AlertMessage[] = [];
    let sentMessages: SentMessage[] = [];

    messageStore.setShowMessageSpinner(true);
    const response = await http<undefined, Alerts>({
        path: `message/all?id=${authenticationService.currentUserValue.clientId}`
    });
    if (response.ok && response.parsedBody) {
        if (response.parsedBody.success)
        {
            response.parsedBody.messages.forEach(m => {
                m.date = dateToLocal(m.date);
                m.acknowledgedDate = dateToLocal(m.acknowledgedDate);
            });
            messages = response.parsedBody.messages;

            response.parsedBody.sentMessages.forEach(m => {
                m.date = dateToLocal(m.date);
            });
            sentMessages = response.parsedBody.sentMessages;
        } else {
            layoutStore.setShowNotification(true, NoticationTypes.error, response.parsedBody.friendlyMessage);
        }
    } else {
        layoutStore.setShowNotification(true, NoticationTypes.error, `API Error: ${response.statusText}`);
    }

    messageStore.setAlertMessages(messages, sentMessages);
    messageStore.setShowMessageSpinner(false);
    return response.ok
}

const setRead = async (id: number) => {
    messageStore.setShowMessageSpinner(true);
    const response = await http<number, boolean>({
        method: 'POST',
        path: `message/setRead?id=${id}`
    });
    if (response.ok && response.parsedBody) {
        messageStore.setAlertMessageAsRead(id);
    }
    messageStore.setShowMessageSpinner(false);
}

const getSendToUsers = async () => {
    const response = await http<number, SendToUsers>({
        path: `message/getSendToUsers?id=${authenticationService.currentUserValue.clientId}`
    });
    if (response.ok && response.parsedBody) {
        if (response.parsedBody.success) {
            return response.parsedBody.users;
        } else {
            layoutStore.setShowNotification(true, NoticationTypes.error, response.parsedBody.friendlyMessage);
        }
    } else {
        layoutStore.setShowNotification(true, NoticationTypes.error, `API Error: ${response.statusText}`);
    }
    return [];
}

const getMessageTypes = async () => {
    const response = await http<number, MessageTypeList>({
        path: 'message/getMessageTypes'
    });
    if (response.ok && response.parsedBody) {
        if (response.parsedBody.success) {
            return response.parsedBody.types;
        } else {
            layoutStore.setShowNotification(true, NoticationTypes.error, response.parsedBody.friendlyMessage);
        }
    } else {
        layoutStore.setShowNotification(true, NoticationTypes.error, `API Error: ${response.statusText}`);
    }
    return [];
}

const sendUserMessage = async (sendToUserId: number, message: string, messageTypeId: number) => {
    messageStore.setShowMessageSpinner(true);

    const response = await http<SendUserMessageRequest, SendUserMessageResponse>({
        method: "POST",
        body: {
            clientId: authenticationService.currentUserValue.clientId,
            sendToUser: sendToUserId,
            message: message,
            currentUserId: -1,
            messageTypeId: messageTypeId
        },
        path: `message/sendMessage`
    });
    messageStore.setShowMessageSpinner(false);

    if (response.ok && response.parsedBody) {
        if (response.parsedBody.success) {
            layoutStore.setShowNotification(true, NoticationTypes.success,"The Message has been Sent!");
            return response.parsedBody;
        } else {
            layoutStore.setShowNotification(true, NoticationTypes.error, response.parsedBody.friendlyMessage);
        }
    } else {
        layoutStore.setShowNotification(true, NoticationTypes.error, `API Error: ${response.statusText}`);
    }

}

const updateStorageMessageCount = (count: number) => {
    const localUser = localStorage.getItem('currentUser');
    const jsonUser: ClientUser = localUser ? JSON.parse(localUser) : null;
    if (jsonUser) {
        jsonUser.profile.alertCount = count;
        localStorage.setItem('currentUser', JSON.stringify(jsonUser));
    }
}

export const alertMessageService = {
    fetchMessages,
    setRead,
    getSendToUsers,
    sendUserMessage,
    getMessageTypes,
    updateStorageMessageCount
};
