import React, {FC} from 'react';

export const Footer: FC = () => {
    const currentYear = () => {
        return new Date().getFullYear();
    }
    return (
        <div className="footer">Copyright &copy; {currentYear()} Smoky Mountain Information Systems </div>
    );

}
export default Footer;