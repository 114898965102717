import { Button } from '@progress/kendo-react-buttons';
import { FC } from 'react';
import { observer } from 'mobx-react';
import '../../../assets/styles/custombutton.scss';

interface Props {
    type: ButtonType;
    onClick?: () => void;
    disabled?: boolean;
    buttonText:string;
    classNames ?:string;
}

export enum ButtonType { BACK, CONTINUE,BUTTON  };

export const CustomButton: FC<Props> = observer(({ type, onClick, disabled,buttonText,classNames}) => {
    return (
        <div>
            {type === ButtonType.BACK && (
                <div>
                    <Button className="backbutton" onClick={onClick} disabled={disabled}>
                        <img src='/images/Back.png' width="13" height="8" alt='back'></img>
                        <span className="back-text">{buttonText}</span>
                    </Button>                    
                </div>
            )}
            {type === ButtonType.CONTINUE && (
                <div>
                    <Button className="continuebutton" onClick={onClick} disabled={disabled}>
                        <span className="continue-text">{buttonText}</span>
                        <img src='/images/Continue.png' width="13" height="8" alt='continue'></img>
                    </Button>
                </div>
            )}   
              {type === ButtonType.BUTTON && (
                <div>
                    <Button className={classNames} onClick={onClick} disabled={disabled}>
                        <span className="continue-text">{buttonText}</span>
                    </Button>
                </div>
            )}           
        </div>
    )
});