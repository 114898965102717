import { makeAutoObservable } from 'mobx'
import { Basics, ClientBasics } from '../models/ClientBasics';
import { ClientContacts, Contact } from '../models/ClientContacts';
import { ClientDetails, Details } from '../models/ClientDetails';
import { ClientInsurance, Insurance } from '../models/ClientInsurance';

export class ClientStore {

    clientBasics: ClientBasics = {
        success: false,
        friendlyMessage: '',
        basics: {
            firstName: '',
            lastName: '',
            middleName: '',
            aka: '',
            maidenName: '',
            dob: undefined,
            email: '',
            genderID: {text: '', value: ''},
            homePhone: '',
            cell: '',
            workPhone: '',
            fax: '',
            address1: '',
            address2: '',
            ssn: '',
            city: '',
            stateAbbrev: {text: '', value: ''},
            zip: '',
            clientID: 0,
            contactMethodID: {text: '', value: ''},
            consentToReceiveMessages: '',
            useClientInfoForMessaging: false,
            messagingContact: {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                cell: '',
                address1: '',
                address2: '',
                city: '',
                stateAbbrev: {text: '', value: ''},
                zip: '',
                relationShip: {text: '', value: ''},
                type: 'Messaging',
                contactID: 0,
                contactTypeId: 0,
                addressId: 0,
                stateId: 0
            }
        }
    };

    clientContacts: ClientContacts = {
        success: false,
        friendlyMessage: '',
        contacts: [
            {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                cell: '',
                address1: '',
                address2: '',
                city: '',
                stateAbbrev: {text: '', value: ''},
                zip: '',
                relationShip: {text: '', value: ''},
                type: 'Emergency',
                contactID: 0,
                contactTypeId: 0,
                addressId: 0,
                stateId: 0
            },
            {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                cell: '',
                address1: '',
                address2: '',
                city: '',
                stateAbbrev: {text: '', value: ''},
                zip: '',
                relationShip: {text: '', value: ''},
                type: 'Legal Guardian',
                contactID: 0,
                contactTypeId: 0,
                addressId: 0,
                stateId: 0
            },
            {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                cell: '',
                address1: '',
                address2: '',
                city: '',
                stateAbbrev: {text: '', value: ''},
                zip: '',
                relationShip: {text: '', value: ''},
                type: 'Responsible Party',
                contactID: 0,
                contactTypeId: 0,
                addressId: 0,
                stateId: 0
            },
            {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                cell: '',
                address1: '',
                address2: '',
                city: '',
                stateAbbrev: {text: '', value: ''},
                zip: '',
                relationShip: {text: '', value: ''},
                type: 'Preferred Physician',
                contactID: 0,
                contactTypeId: 0,
                addressId: 0,
                stateId: 0
            }
        ]
    };

    clientInsurance: ClientInsurance = {
        success: false,
        friendlyMessage: '',
        insurance: {
            clientID: 0,
            companyPrimary: {text: '', value: ''},
            groupintPrimary: '',
            subscriberIDPrimary: '',
            dateIssuedPrimary: undefined,
            expireDatePrimary: undefined,
            deductiblePrimary: undefined,
            coPayPrimary: undefined,
            relationshipPrimary: {text: '', value: ''},
            firstNameInsured: '',
            lastNameInsured: '',
            phoneNoInsured: '',
            dobInsured: undefined,
            companySecondary: {text: '', value: ''},
            groupintSecondary: '',
            subscriberIDSecondary: '',
            dateIssuedSecondary: undefined,
            expireDateSecondary: undefined,
            deductibleSecondary: undefined,
            coPaySecondary: undefined,
            relationshipSecondary: {text: '', value: ''},
            firstNameSecondaryInsured: '',
            lastNameSecondaryInsured: '',
            phoneNoSecondaryInsured: '',
            dobSecondaryInsured: undefined,
            contactId: undefined,
            secondaryContactId: undefined
        }
    };

    clientDetails: ClientDetails = {
        success: false,
        friendlyMessage: '',
        details: {
            clientID: 0,
            raceID: {text: '', value: ''},
            ethnicityID: {text: '', value: ''},
            languageID: {text: '', value: ''},
            isPregnant: false,
            isEnglishPro: false,
            livingArrangementID: {text: '', value: ''},
            residenceTypeID: {text: '', value: ''},
            adultsInHouseHold: 0,
            childrenInHouseHold: 0,
            maritalStatusID: {text: '', value: ''},
            annualIncome: 0,
            employmentStatusID: {text: '', value: ''},
            disabilityID: {text: '', value: ''},
            smokingStatusID: {text: '', value: ''},
            isVeteran: false,
            schoolID: {text: '', value: ''},
            gradeID: {text: '', value: ''},
            targetPopulationID: {text: '', value: ''}
        }
    };

    selectTab: number = 0;
    showClientSpinner: boolean = false;
    selectedContactType: string = '';
    showPasswordDialog: boolean = false;
    showAvatarDialog: boolean = false;
    avatarImage: string = '';
    updateContact = false;
    showMessagingConsent: boolean = false;

    setShowMessagingConsent = (show: boolean) => {
        this.showMessagingConsent = show;
    };

    setShowPasswordDialog = (show: boolean) => {
        this.showPasswordDialog = show;
    };

    setShowAvatarDialog = (show: boolean) => {
        this.showAvatarDialog = show;
    };

    setAvatarImage = (imageBase64: string) => {
        this.avatarImage = imageBase64;
    };

    setClientBasics = (cb: ClientBasics) => {
        this.clientBasics = cb;
    };

    setBasics = (basics: Basics  ) => {
        this.clientBasics.basics = basics;
    };

    setClientDetails = (cd: ClientDetails) => {
        this.clientDetails = cd;
    };

    setDetails = (details: Details) => {
        this.clientDetails.details = details;
    }

    setClientContacts = (cd: ClientContacts) => {
        this.clientContacts = cd;
    };

    setClientInsurance = (cd: ClientInsurance) => {
        this.clientInsurance = cd;
    };

    setInsurance = (ins: Insurance) => {
        this.clientInsurance.insurance = ins;
    }

    setSelectedTab = (tab: number) => {
        this.selectTab = tab;
    };

    setSelectedContactType = (type: string) => {
        this.selectedContactType = type;
    }

    setShowClientSpinner = (flag: boolean) => {
        this.showClientSpinner = flag;
    };

    setContact(contact: Contact) {
        const idx = this.clientContacts.contacts.findIndex((c) => c.type === contact.type);
        this.clientContacts.contacts[idx] = contact;
    }

    constructor() {
        makeAutoObservable(this)
    };
}
