import { FC } from 'react';
import { ClientUser } from '../../models/User';
import { observer } from 'mobx-react';
import { useClientStore, useSettingStore } from '../../hooks/hooks';
import { Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from '@progress/kendo-react-buttons';
import { useHistory } from 'react-router-dom';
import { envelopeIcon } from "@progress/kendo-svg-icons";

interface Props {
    user: ClientUser;
}

export const MessagingConsent: FC<Props> = observer(({user}) => {
    const history = useHistory();
    const settingStore = useSettingStore();
    const clientStore = useClientStore();

    const gotoMessagingPref = () => {
        let idx = 4;
        if (!settingStore.getSetting().showBasics) idx -= 1;
        if (!settingStore.getSetting().showDetails) idx -= 1;
        if (!settingStore.getSetting().showInsurance) idx -= 1;
        clientStore.setSelectedTab(idx);

        history.push('/profile')
    }

    return (
        <Window title="Consent to Receive Messages" resizable={false} initialWidth={350} initialHeight={250} themeColor='primary' modal={true}
            minimizeButton={() => null}
            maximizeButton={() => null}
            closeButton={() => null}>
            <div>
                Please take a moment to update your messaging preferences by clicking the button below.
            </div>
            <WindowActionsBar layout="center">
                <Button type="button" themeColor={"primary"} rounded={"full"} size={"medium"}  svgIcon={envelopeIcon} onClick={gotoMessagingPref}>Update Messaging Preferences</Button>
            </WindowActionsBar>
        </Window>
    )
});

export default MessagingConsent;