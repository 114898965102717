import { dateToLocal, dateToLocalDate } from '../helpers';
import { Appointments, AppointmentsRequest, BookAppointment, BookAppointmentRequest } from '../models/Appointments';
import { AppointmentStore } from '../stores/appointmentStore';
import { LayoutStore } from '../stores/layoutStore';
import { http } from './http';
import { NoticationTypes, SaveAppointmentStatus } from '../models/enums';
import { authenticationService } from '../services';
import { AvailabilityRequest, PractitionerAvailability, PractitionerData, Practitioners, UserBillingCodes, UserImage } from '../models/Practitioner';
import { PractitionerStore } from '../stores/practitionerStore';

const fetchAppointments = async (store: AppointmentStore, layoutStore: LayoutStore) => {
    store.setShowAppointmentSpinner(true);

    let appointments: Appointments = {
        success: false,
        friendlyMessage: '',
        appointments: [],
        total: 0
    }

    const currentUser = authenticationService.currentUserValue;

    const response = await http<AppointmentsRequest, Appointments>({
        method: "POST",
        body: {
            upcomingOnly: false,
            maximumResponseSize: 3,
            minimumResponseSize: 3,
            clientId: currentUser.clientId,
            startDate: '',
            endDate: '',
            skip: 0,
            take: 100
        },
        path: `appointments/custom`
    });
    if (response.ok && response.parsedBody) {
        if (response.parsedBody.success) {
            response.parsedBody.appointments.forEach(m => {
                m.startTime = dateToLocal(m.startTime);
                m.endTime = dateToLocal(m.endTime);
                m.serviceDate = dateToLocal(m.serviceDate);
            })
            appointments = response.parsedBody;
        }
        else {
            layoutStore.setShowNotification(true, NoticationTypes.error, response.parsedBody.friendlyMessage);
        }
    } else {
        layoutStore.setShowNotification(true, NoticationTypes.error, `API Error: ${response.statusText}`);
    }

    store.setAppointments(appointments);
    store.setShowAppointmentSpinner(false);

    return response.ok;
}

const fetchAllAppointments = async (store: AppointmentStore, layoutStore: LayoutStore, skip: number, take: number, start: string, end: string) => {
    store.setShowAppointmentSpinner(true);

    let appointments: Appointments = {
        success: false,
        friendlyMessage: '',
        appointments: [],
        total: 0
    }

    const currentUser = authenticationService.currentUserValue;

    const response = await http<AppointmentsRequest, Appointments>({
        method: "POST",
        body: {
            upcomingOnly: false,
            maximumResponseSize: 3,
            minimumResponseSize: 3,
            clientId: currentUser.clientId,
            startDate: start,
            endDate: end,
            skip,
            take


        },
        path: `appointments/bydate`
    });
    if (response.ok && response.parsedBody) {
        if (response.parsedBody.success) {
            response.parsedBody.appointments.forEach(m => {
                m.startTime = dateToLocal(m.startTime);
                m.endTime = dateToLocal(m.endTime);
                m.serviceDate = dateToLocal(m.serviceDate);
            })
            appointments = response.parsedBody;
        }
        else {
            layoutStore.setShowNotification(true, NoticationTypes.error, response.parsedBody.friendlyMessage);
        }
    } else {
        layoutStore.setShowNotification(true, NoticationTypes.error, `API Error: ${response.statusText}`);
    }

    store.setAllAppointments(appointments);
    store.setShowAppointmentSpinner(false);

    return response.ok;
}

const fetchPractitioner = async (store: PractitionerStore, layoutStore: LayoutStore) => {

    let practitioner: Practitioners = {
        success: false,
        friendlyMessage: '',
        practitioners: []
    }
    const response = await http<undefined, Practitioners>({
        path: 'appointments/practitioner'
    });
    if (response.ok && response.parsedBody) {
        if (response.parsedBody.success) {
            practitioner = response.parsedBody;
        }
        //else {
            //layoutStore.setShowNotification(true, NoticationTypes.error, response.parsedBody.friendlyMessage);
        //}
    } else {
        layoutStore.setShowNotification(true, NoticationTypes.error, `API Error: ${response.statusText}`);
    }

    store.setPractitioners(practitioner.practitioners);

    return response.ok;
}

const fetchUserBillingCodes = async (store: PractitionerStore, userId: number) => {

    let userBillingCodes: UserBillingCodes = {
        success: false,
        friendlyMessage: '',
        userBillingCodes: []
    }
    const response = await http<undefined, UserBillingCodes>({
        path: `appointments/userbillingcodes?practitiionerId=${userId}`
    });

    if (response.ok && response.parsedBody) {
        if (response.parsedBody.success) {
            userBillingCodes = response.parsedBody;
        }
        else {
            //layoutStore.setShowNotification(true, NoticationTypes.error, response.parsedBody.friendlyMessage);
        }
    } else {
        //layoutStore.setShowNotification(true, NoticationTypes.error, `API Error: ${response.statusText}`);
    }

    store.setBillingCodeSelections(userBillingCodes.userBillingCodes);

    return response.ok;
}

const fetchUserImage = async (store: PractitionerStore, practitioner: PractitionerData) => {
    const response = await http<undefined, UserImage>({
        path: `appointments/userThumbnail?userId=${practitioner.id}`
    });

    if (response.ok && response.parsedBody) {
        if (response.parsedBody.success) {
            store.setPractitiionerUserImage(practitioner.id, response.parsedBody.imageData);
        }
        else {
            store.setPractitiionerUserImage(practitioner.id, '');
        }
    }

    return response.ok;
}

const fetchPractitionerAvailability = async (store: PractitionerStore, month: string, selectedDate: string, monthFlag: string, id: number, billingCodeId: number, isJaas: boolean) => {
    let availRequest: AvailabilityRequest = {
        month: month,
        selectedDate: selectedDate,
        practitionerId: id,
        billingCodeId,
        isJaas
    }

    const response = await http<AvailabilityRequest, PractitionerAvailability>({
        path: `appointments/practitionerAvailability`,
        body: availRequest,
        method: "POST"
    });

    if (response.ok && response.parsedBody) {
        if (response.parsedBody.success) {
            store.setPractitionerAvailability(response.parsedBody);
            return response.ok;
        }
    }

    store.setPractitionerAvailability({ availableDays: [], success: false, friendlyMessage: ''});

    return response.ok;
}

const saveAppointment = async (store: PractitionerStore, layoutStore: LayoutStore, apptStore: AppointmentStore, selectedDate: string, time: string, id: number, billingCodeId: number, isJaas: boolean, isPending: boolean) => {

    let apptRequest: BookAppointmentRequest = {
        practitionerId: id,
        selectedDate: selectedDate,
        time: time,
        otp: '',
        billingCodeId,
        isJaas
    };

    let apptResponse: BookAppointment = {
        success: false,
        friendlyMessage: '',
        bookingId: ''
    };

    apptStore.setApptSaveStatus(SaveAppointmentStatus.InProcess);
    apptStore.setShowAppointmentSpinner(true);
    apptStore.setAddAppointmentMessage('');

    const response = await http<BookAppointmentRequest, BookAppointment>({
        path: 'appointments/bookAppointmentExistingClient',
        method: 'POST',
        body: apptRequest
    });

    if (response.ok && response.parsedBody) {
        if (response.parsedBody.success) {
            layoutStore.setShowNotification(true, NoticationTypes.success, "New Appointment Saved!");
            apptResponse = response.parsedBody;

            if (!isPending) {
                apptStore.setAddAppointmentMessage(`Your appointment is scheduled on ${dateToLocalDate(selectedDate)} at ${store.selectedTimeslot}`);
            } else {
                apptStore.setAddAppointmentMessage(`Your appointment request For ${dateToLocalDate(selectedDate)} at ${store.selectedTimeslot} has been sent and is pending approval. You will be contacted for further instructions. Thank you for your patience.`);
            }
            apptStore.setApptSaveStatus(SaveAppointmentStatus.Success)
        }
        else {
            layoutStore.setShowNotification(true, NoticationTypes.error, response.parsedBody.friendlyMessage);
            apptStore.setApptSaveStatus(SaveAppointmentStatus.Failed);
            apptStore.setAddAppointmentMessage(response.parsedBody.friendlyMessage);
        }
    } else {
        apptStore.setApptSaveStatus(SaveAppointmentStatus.Failed);
        layoutStore.setShowNotification(true, NoticationTypes.error, `API Error: ${response.statusText}`);
    }

    apptStore.setShowAppointmentSpinner(false);
    return apptResponse;
}

export const appointmentService = {
    fetchAppointments,
    fetchPractitioner,
    fetchUserImage,
    fetchPractitionerAvailability,
    saveAppointment,
    fetchUserBillingCodes,
    fetchAllAppointments
}