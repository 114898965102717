import { computed, makeAutoObservable } from 'mobx'
import { DocumentGroupList } from '../models/Documents';
import { PickListItems, QnAForm, QnAFormsList, QnAFormsName, Question, Token } from '../models/QnAForm';

export class LibraryStore {

    qnAFormsList: QnAFormsList = {
        success: false,
        friendlyMessage: '',
        qnAList: []
    };

    qnAForm: QnAForm = {
        success: false,
        friendlyMessage: '',
        form: {
            formName: '',
            qnACategoryID: 0,
            referenceId: 0,
            noteId: 0,
            titleGroup: '',
            titleGroupDate: '',
            titleGroupDateTime: '',
            editMode: false,
            active: false,
            createReportSaveDoc: false,
            canViewReportDoc: false,
            questions: []
        }
    };

    formRefresh: boolean = false;

    documentGroupList: DocumentGroupList = {
        success: false,
        friendlyMessage: '',
        documentGroups: []
    };

    selectTab: number = 0;
    showLibrarySpinner: boolean = false;
    selectedForm: undefined | QnAFormsName = {
        clientQnAID: 0,
        categoryName: '',
        categoryDescription: '',
        lastChange: '',
        lastChangedBy: '',
        nextDue: ''
    };

    pickListItems: PickListItems[] = [{
        success: false,
        friendlyMessage: '',
        pickListTypeID: 0,
        listValues: []
    }];

    documentViewParms: string = "";
    selectDocName: string = "";
    selectedDocId: number = 0;
    selectedDocThreadId: number = 0;
    completeDoc: boolean = false;
    documentReadonly: boolean = false;
    documentImageBytes: string = "";

    ccdaHtml: string = "";
    ccdaAutomationEnabled: boolean = false;
    showAutomationMessage: boolean = false;

    setAutomationFlag = (flag: boolean) => {
        this.ccdaAutomationEnabled = flag;
    };
    setShowAutomationMessage = () => {
        this.showAutomationMessage = true;
    };

    setCCDAHtml = (html: string) => {
        this.ccdaHtml = html;
    };

    getQuestions = (): Question[] => {
        return this.qnAForm.form.questions;
    };

    setCompleteDoc = (comp: boolean) => {
        this.completeDoc = comp;
    };

    setSelectedTab = (tab: number) => {
        this.selectTab = tab;
    };

    setShowLibrarySpinner = (flag: boolean) => {
        this.showLibrarySpinner = flag;
    };

    setSelectedForm = (form: QnAFormsName | undefined) => {
        this.selectedForm = form;
    };

    setQnAFormsList = (list: QnAFormsList) => {
        this.qnAFormsList = list;
    };
    setDocumentGroupList = (list : DocumentGroupList) => {
        this.documentGroupList = list;
    };

    setQnAForm = (form: QnAForm) => {
        this.qnAForm = form;
    };

    setFormRefresh = (refresh: boolean) => {
        this.formRefresh = refresh;
    }

    setQnAFormSuccess(success: boolean) {
        this.qnAForm.success = success;
    };

    setQnAFormQnAId = (questionId: number, qnaId: number) => {
        let q = this.getQuestion(questionId);
        q!.qnAID = qnaId;
        q!.errorMessage = "";
    };

    setQnAError = (questionId: number, error: string) => {
        let q = this.getQuestion(questionId);
        q!.errorMessage = error;
        q!.answerText = "";
    };

    setDocumentReadonly = (readOnly: boolean) => {
        this.documentReadonly = readOnly;
    };

    setTouched = (q: Question, touched: boolean) => {
        q!.touched = touched;
    };

    setTokenReplaced = (q: Question) => {
        q.tokenReplaced = true;
    }

    addPickListItem = (item: PickListItems) => {
        this.pickListItems.push(item);
    };

    updateQnAFormQuestion = (id: number, value: string, text: string) => {
        let q = this.getQuestion(id);
        q!.answerText = text;
        q!.answer = value;
    };

    updateQnAFormVisible = (q: Question, value: boolean) => {
        q!.visible = value;
    };

    updateQnAFormRemarks = (id: number, value: string) => {
        let q = this.getQuestion(id);
        q!.remarks = value;
    };

    updateQnAFormFile = (id: number, value: string | undefined) => {
        let q = this.getQuestion(id);
        q!.answer = value ? 'sig' : '';
        q!.answerFile = value;
    };

    updateTokenValue = (token: Token, value: string) => {
        token.value = value;
        token.isDirty = true;
    }

    getQnAQuestionValue = (id: number) => {
        let q = this.getQuestion(id);
        return  q!.answerText;
    };

    getQuestion = (id: number) => {
        return this.qnAForm.form.questions.find(q => q.qnAQuestionID === id);
    };

    getDistinctDocGroups = () => {
        return this.documentGroupList.documentGroups.filter((dg, i, arr) => arr.findIndex(t => t.typeName === dg.typeName) === i);
    };

    getDocumentGroupsToComplete = () => {
        return this.documentGroupList.documentGroups.filter((dg, i) => dg.typeName === 'Documents to Complete');
    };

    getDocumentGroupsCompleted = () => {
        return this.documentGroupList.documentGroups.filter((dg, i) => dg.typeName !== 'Documents to Complete');
    };

    @computed get formHasErrors(): boolean {
        let hasErrors = false;
        this.qnAForm.form.questions.forEach(q => {
            if (q.errorMessage) {
                hasErrors = true;
            }
            if (q.required && !q.answer) {
                hasErrors = true;
            }
        });
        return hasErrors;
    };

    @computed isRequiredDisabled(q: Question): boolean {
         const prevQs = this.qnAForm.form.questions.filter((fq) => {
             return fq.required && !fq.answer && fq.displayOrder < q.displayOrder;
         })
         return prevQs.length > 0;
    };

    @computed isRequiredDisabledAndTokens(q: Question) : boolean {
        let required = this.isRequiredDisabled(q);
        let dirtyTokens = q.tokens.filter(t => t.isDirty === true).length;

        return required || q.tokens.length > dirtyTokens;
    }

    qnaTokenText(q: Question): string {
        if (q.tokenized){
            if (q.touched) {
                if (!q.tokenReplaced){
                    q.answerText = q.populatedTokens ?? "";
                    //q.answer = q.answerText; this creates issues ???
                    q.tokenReplaced = true;
                }
                return q.answerText ?? "";
            }
            else {
                return "";
            }
        }
        return q.answerText ?? ""
    }

    setDocViewerContext(id: number, threadId: number, clientId: number) {
        const d = new Date()
        const gmtHours = -d.getTimezoneOffset()/60;

        this.documentViewParms = `DocumentID=${id}&DocumentThreadID=${threadId}&ClientID=${clientId}&key=8BN5KmZNgSkh&utcOffset=${gmtHours}`;
    };

    setDocPDFViewerContext(id: number, threadId: number, clientId: number, docName: string) {
        this.documentViewParms = `${id}|${threadId}|${clientId}`;
        this.selectDocName = docName;
    };

    setDocImageViewerContext(id: number, threadId: number, docName: string) {
        this.selectDocName = docName;
        this.selectedDocId = id;
        this.selectedDocThreadId = threadId;
    };

    setDocumentImageBytes(bytes: string) {
        this.documentImageBytes = bytes;
    }

    constructor() {
        makeAutoObservable(this)
    };
}
