import React, { FC, useEffect } from 'react';
import { Card, CardBody, CardImage } from '@progress/kendo-react-layout';
import { ClientUser } from '../../models/User';
import { useLayoutStore, useAppointmentStore, useSettingStore, usePaymentStore, usePractitionerStore } from '../../hooks/hooks';
import { appointmentService, authenticationService } from '../../services';
import { observer } from 'mobx-react';
import { LoadingSpinner } from '../shared/LoadingSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLaptopMedical, faCreditCard, faArrowRight, faAddressCard } from '@fortawesome/free-solid-svg-icons'
import appSettings from '../../AppSettings.json';
import { Button } from '@progress/kendo-react-buttons';
import { Appointment } from '../../models/Appointments';
import { useHistory } from 'react-router-dom';
import ScheduleSelection from './scheduler/ScheduleSelection';

interface Props {
    user: ClientUser;
}

export const HomeAppointments: FC<Props> = observer(({ user }) => {
    const layoutStore = useLayoutStore();
    const appointmentStore = useAppointmentStore();
    const settingStore = useSettingStore();
    const paymentStore = usePaymentStore();
    const practitionerStore = usePractitionerStore();
    const history = useHistory();


    useEffect(() => {
        (async () => await appointmentService.fetchAppointments(appointmentStore, layoutStore).then(() => {
            (async () => await appointmentService.fetchPractitioner(practitionerStore, layoutStore).then(async () => {
                if (practitionerStore.practitioners.length === 1 && practitionerStore.selectedPractitioner) {
                    await appointmentService.fetchUserBillingCodes(practitionerStore, practitionerStore.selectedPractitioner.id);
                }
            }))();
        }))();
    }, [appointmentStore, layoutStore, practitionerStore, settingStore]);

    const checkIfFuture = (sal: string) => {
        let salDate = new Date(sal);
        if (salDate >= new Date()) {
            return 'future_appointment';
        }
        return "";
    }

    const makePayment = async (apt: Appointment) => {

        paymentStore.setSalPayment(
            apt.salid,
            apt.noteID ? apt.noteID : 0,
            apt.fullName,
            apt.startTime,
            apt.serviceDesc,
            apt.amount,
            apt.salNumber,
            apt.noteNumber
        );
        paymentStore.setSelectedTab(0);
        history.push('/payment');
    }
    const newAppointment = () => {
        appointmentStore.setAddAppointmentMessage("");
        practitionerStore.setShowNewAppointment(!practitionerStore.showNewAppointment);
    }

    const allAppointments = () => {
        history.push('/allappointments');
    }

    const getAppointmentText = () => {
        if (practitionerStore.showNewAppointment) {
            return "View Current Appointments";
        }
        if (settingStore.getSetting().pendingAppointment){
            return "Request Appointment";
        } else {
            return "Schedule Appointment";
        }
    }

    return (
        <div className="appointments" id="appointments">
            <div className="row appointments-navigation">
                <h4>Appointments</h4>
                <LoadingSpinner show={appointmentStore.showAppointmentSpinner} />
                {!appointmentStore.showAppointmentSpinner && <>
                    {settingStore.getSetting().showSelfScheduler && authenticationService.currentUserValue.excludeFromSelfScheduler === 0 && practitionerStore.practitioners && practitionerStore.practitioners.length > 0 &&
                        <Button fillMode={"flat"} size={"small"} onClick={newAppointment} className="view_appointment_button">
                            {getAppointmentText()}
                            <FontAwesomeIcon icon={faAddressCard} className="blue2 ml-2" size='sm' />
                        </Button>
                    }

                    <Button fillMode={"flat"} className="view_appointment_button" onClick={allAppointments}>
                        View Appointments
                        <FontAwesomeIcon icon={faArrowRight} className="blue2 ml-2" size='sm' />
                    </Button>
                </>
                }
            </div>
            {settingStore.getSetting().showSelfScheduler && practitionerStore.showNewAppointment &&
                <div className='mb-4 mt-2'>
                    <ScheduleSelection></ScheduleSelection>
                </div>
            }
            <div className="cardHolder mt-1">
                {!practitionerStore.showNewAppointment && appointmentStore.appointments.appointments.map((item, i) => {
                    return (
                        <div key={i}>
                            <Card orientation="horizontal">
                                <CardImage className= {`doctor_image ${checkIfFuture(item.startTime)}`} src="/images/doctor2.png" /> 
                                <div className="k-vbox">
                                    <CardBody>
                                        <div className="user-name">{item.fullName}</div>
                                        <div className="info">
                                            {item.phone &&
                                                <div className='phone_number'>
                                                    <a href={`tel:+1${item.phone}`}>
                                                        {item.phone}
                                                    </a>
                                                </div>
                                            }
                                            {item.location &&
                                                <div className='location'>&nbsp;|&nbsp;{item.location}</div>
                                            }
                                        </div>
                                        <div className={`appointment-card ${item.amount ? 'amount-present' : 'amount-absent'}`}>
                                            {item.amount && (
                                                <div className="make-payment">
                                                    {!item.hasPayment && item.amount && !settingStore.getSetting().hidePayments &&
                                                        <Button fillMode={"link"} themeColor="dark" size={"small"} rounded={"small"} onClick={() => makePayment(item)}>
                                                            <FontAwesomeIcon icon={faCreditCard} className="make_payment"></FontAwesomeIcon>&nbsp;
                                                            Make Payment - ${item.amount}
                                                        </Button>
                                                    }
                                                </div>
                                            )}
                                        </div>
                                        <hr className='horizontal_line' />
                                        <div className="time">
                                            {item.startTime} | <span className = "tab-space"> {item.duration}Mins.</span> 
                                        </div>
                                        <div className={item.status.startsWith('Cancelled') ? 'status cancelled' : 'status'}>
                                            {item.status}
                                            {item.secureVideoConnectCode &&
                                                <div className="meeting" title="Secure Video Meeting">
                                                    <a href={`${appSettings.secureVideoUri}${item.secureVideoConnectCode}`} target='_blank' rel='noreferrer'>
                                                        Join Meeting <FontAwesomeIcon icon={faLaptopMedical} size='lg' className="blue2"></FontAwesomeIcon>
                                                    </a>
                                                </div>
                                            }
                                            {item.jaasLink &&
                                                <div className="meeting" title="Jaas Video Meeting">
                                                    <a href={`${item.jaasLink}`} target='_blank' rel='noreferrer'>
                                                        Join Meeting <FontAwesomeIcon icon={faLaptopMedical} size='lg' className="blue2"></FontAwesomeIcon>
                                                    </a>
                                                </div>
                                            }
                                        </div>
                                    </CardBody>
                                </div>
                            </Card>
                        </div>
                    )
                })
                }
            </div>
            {!practitionerStore.showNewAppointment && appointmentStore.appointments.appointments.length === 0 &&
                <div className="row d-flex justify-content-center mt-4"><h5>No Appointments at this time.</h5></div>
            }

        </div>
    )
});

export default HomeAppointments;