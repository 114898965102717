import React, {FC, useState} from 'react';
import { Form, required, email, Values } from '../shared/Form';
import { Field } from '../shared/Field';
import { authenticationService } from '../../services';
import { ForgotResponseModel } from '../../models/User';
import { Link } from 'react-router-dom';
import { useSettingStore } from '../../hooks/hooks';

export const Forgot: FC = () => {
    const settingStore = useSettingStore();

    const initResponse: ForgotResponseModel = {
        success: false,
        friendlyMessage: '',
        officeEmail: '',
        emailCount: 0
    }
    const [forgotResponse, setForgotResponse ] = useState(initResponse);

    const handleSubmit = async (values: Values) => {
        const response = await authenticationService.forgot(values.email, values.name);
        response.success = true;
        setForgotResponse(response);

        return { success: response.emailCount <= 1 }
    };

    return (
        <div className='login-bg'>
            <div className="login">
                <Form
                    submitCaption="Send Details"
                    validationRules={{
                        email: [{ validator: required }, { validator: email }],
                    }}
                    onSubmit={handleSubmit}
                    failureMessage={ forgotResponse.friendlyMessage}
                    successMessage={ forgotResponse.friendlyMessage}>
                    <div>
                        <div className='text-center'>
                            { settingStore.logoLoaded &&
                                <img className="logo" src={settingStore.logo} alt=""/>
                            }
                        </div>
                        <div className='text-center'>
                            <h5>Forgot Password</h5>
                        </div>
                        <p>
                            Enter the email address associated with your Login ID, your login details will be sent to that email address.
                        </p>
                        <p>
                            If you don't have an email address, Please contact our office if you have forgotten your password or need to change your password.
                        </p>
                        <Field name="email" label="Email Address"/>

                        { forgotResponse.emailCount > 1 &&
                            <Field name="name" label="First Name"/>
                        }
                        { forgotResponse.success && forgotResponse.emailCount <= 1 &&
                            <Link className='backLink' to="/login">Back to Login</Link>
                        }
                    </div>
                </Form>

            </div>
        </div>
    );
}
export default Forgot;