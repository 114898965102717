import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';
import App from './App';
import { Provider } from 'mobx-react'
import { stores } from "./stores";
import { registerLicense } from '@syncfusion/ej2-base';
import appSettings from './AppSettings.json';
import CacheBuster from 'react-cache-buster';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";

registerLicense(appSettings.syncFusionLicense);
const isProduction = process.env.NODE_ENV === 'production';

const browserHistory = createBrowserHistory();
var reactPlugin = new ReactPlugin()
// *** Add the Click Analytics plug-in. ***
/* var clickPluginInstance = new ClickAnalyticsPlugin();
    var clickPluginConfig = {
    autoCapture: true
}; */
var appInsights = new ApplicationInsights({
    config: {
        connectionString: 'InstrumentationKey=be12ec7a-d72d-407b-b481-2f3c7a27c099;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/',
        // *** If you're adding the Click Analytics plug-in, delete the next line. ***
        extensions: [reactPlugin],
    // *** Add the Click Analytics plug-in. ***
    // extensions: [reactPlugin, clickPluginInstance],
        extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory }
    // *** Add the Click Analytics plug-in. ***
    // [clickPluginInstance.identifier]: clickPluginConfig
        }
    }
});
appInsights.loadAppInsights();

ReactDOM.render(
  <Provider {...stores}>
    <CacheBuster
      currentVersion={appSettings.version}
      isEnabled={isProduction}
      isVerboseMode={true}
      loadingComponent={<span></span>}
    >
      <AppInsightsContext.Provider value={reactPlugin}>
        <App />
      </AppInsightsContext.Provider>
    </CacheBuster>
  </Provider>,
  document.getElementById('root')
);


