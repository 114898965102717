import { makeAutoObservable } from 'mobx'
import { ChangePasswordModel } from '../models/ChangePassword'

export class PasswordStore {

    changeRequest: ChangePasswordModel = {
        current: '',
        newPassword: ''
    };

    changeResponseSuccess: boolean = false;
    changeResponseMessage: string = '';
    showSpinner: boolean = false;
    triedToChange: boolean = false;

    setChangeRequest = (request: ChangePasswordModel) => {
        this.changeRequest.current = request.current;
        this.changeRequest.newPassword = request.newPassword;
        this.changeResponseSuccess = false;
    };

    setChangeResponse = (success: boolean, message: string) => {
        this.changeResponseSuccess = success;
        this.changeResponseMessage = message;
    };

    setShowSpinner = (show: boolean) => {
        this.showSpinner = show;
        if (show) {
            this.triedToChange = true;
        }
    };

    constructor() {
        makeAutoObservable(this)
    };
}