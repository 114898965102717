import { makeAutoObservable } from 'mobx'
import { ActivityLog } from '../models/ActivityLog';

export class ActivityStore {

    activityLog: ActivityLog = {
        success: false,
        friendlyMessage: '',
        totalRecords: 0,
        activity: []
    };
    skip: number = 0;
    take: number = 100;

    showActivitySpinner: boolean = false;

    setActivityLog = (logs: ActivityLog) => {
        this.activityLog = logs;
    };
    setSkipTake = (skip: number, take: number) => {
        this.skip = skip;
        this.take = take;
    }

    setShowActivitySpinner = (flag: boolean) => {
        this.showActivitySpinner = flag;
    };

    constructor() {
        makeAutoObservable(this)
    }
}
