import { FC, useEffect, } from 'react';
import NumberFormat from 'react-number-format';
import {RouteComponentProps, useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import '../../assets/styles/home.scss';
import { useClientStore, usePaymentStore, useSettingStore } from '../../hooks/hooks';
import { authenticationService } from '../../services';
import HomeAppointments from './Appointments';
import HomeForms from './Forms';
import HomeInformation from './Information';
import HomeInsurance from './Insurance';
import HomeMessage from './Messages';
import { observer } from 'mobx-react';
import { useCacheBuster } from 'react-cache-buster';
import OrangeCalendar from '../../assets/images/Orange_Calendar.png';
import CoinPedestal from '../../assets/images/Coin_Pedestal.png';
import parse from 'html-react-parser';
import MessagingConsent from './MessagingConsent';
import { LoadingSpinner } from '../shared/LoadingSpinner';

interface RouteParms {
    id: string;
}

export const Home: FC<RouteComponentProps<RouteParms>> = observer(({ match }) => {
    const history = useHistory();
    const { checkCacheStatus } = useCacheBuster();
    const settingStore = useSettingStore();
    const paymentStore = usePaymentStore();
    const clientStore = useClientStore();
    const currentUser = authenticationService.currentUserValue;
    useEffect(() => {
        if (sessionStorage.getItem("password-open") === "1") {
            clientStore.setShowPasswordDialog(true);
        }
        sessionStorage.setItem("password-open", "");

        if (match.path.indexOf('forms') > 0) {
            sessionStorage.setItem("route-to-forms", match.params.id ?? '0');
        }

        if (sessionStorage.getItem("route-to-forms")) {
            history.push('/library');
        }

        if (!sessionStorage.getItem("versionCheck")) {
            sessionStorage.setItem("versionCheck", "1");
            checkCacheStatus();
        }
        clientStore.setShowMessagingConsent(currentUser.needMessagingConsent);

    }, [currentUser, match, history, clientStore, checkCacheStatus]);

    const gotoViewPaymentOptions = () => {
        paymentStore.setSelectedTab(0);
        paymentStore.resetIsSalPayment();
        history.push('/payment');
    };

    const gotoContactProvider = () => {
        paymentStore.setSelectedTab(2);
        history.push('/payment');
    };

    const gotoViewPayments = () => {
        paymentStore.setSelectedTab(1);
        history.push('/payment');
    };

    const getBalanceDue = () => {
        if (paymentStore.balanceDue === 0 && currentUser.amountDue > 0) {
            return currentUser.amountDue;
        }
        return paymentStore.balanceDue;
    };

    return (<>
        <div className="home">
        {clientStore.showMessagingConsent && settingStore.settingsLoaded &&
                <MessagingConsent user={currentUser}/>
        }
        <LoadingSpinner show={!settingStore.settingsLoaded}></LoadingSpinner>
            { settingStore.settingsLoaded && <>
            <Row className="information-cards-section">
                <Col sm="12" className="front-desk-and-payment-cards">
                    <div className="front-desk">
                        <img src={OrangeCalendar} className="calendar-image" alt=''></img>
                        <div className="welcome-text-message">{parse(settingStore.getSetting().instructions)}</div>
                    </div>

                    {(settingStore.getSetting().showTotalDue === true || settingStore.getSetting().hidePayments === false) &&
                        <div className="payment-section">
                            <Row className='payment-area'>
                                <h3 className="billing-title">Billing</h3>
                                <img src={CoinPedestal} className="pedestal-image" alt=''></img>
                                <div className="due">
                                    <Col className="due">
                                        <div className="due-content">
                                            {settingStore.getSetting().showTotalDue &&
                                                <div>
                                                    <span className="due-message">You currently have a balance due of</span>
                                                    <span className="due-amount">
                                                        <NumberFormat value={getBalanceDue()} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} decimalSeparator="." fixedDecimalScale={true}></NumberFormat>
                                                    </span>
                                                </div>
                                            }

                                            {!settingStore.getSetting().hidePayments &&
                                                <div className="home-buttons">

                                                    <div className="btn-flex">
                                                        <Button block={false} size="sm" onClick={gotoViewPaymentOptions} className="view-payment-options-btn">
                                                            View Payment Options
                                                        </Button>
                                                        {settingStore.getSetting().billingContactEmail &&
                                                            <Button block={false} size="sm" onClick={gotoContactProvider} className="contact-billing-btn">
                                                                Contact Billing
                                                            </Button>
                                                        }
                                                        {settingStore.getSetting().showBillingHistory &&
                                                            <Button block={false} size="sm" onClick={gotoViewPayments} className="view-payment-history-btn">
                                                                View Payment History
                                                            </Button>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </Col>
                                </div>
                            </Row>
                        </div>
                    }
                </Col>
            </Row>
            <div className='suggested_actions mt-5'>
                <h4>My records</h4>
                <div className="sectionOuter">

                    <Row>
                        {settingStore.getSetting().showMessaging &&
                            <Col sm={12} lg={6} md={6} >
                                <div>
                                    <HomeMessage user={currentUser} />
                                </div>
                            </Col>
                        }

                        <Col sm={12} lg={6} md={6} className="card_layout" >
                            <div>
                                <HomeInformation user={currentUser} />
                            </div>
                        </Col>
                        <Col sm={12} lg={6} md={6}>
                            <div>
                                <HomeForms user={currentUser} />
                            </div>
                        </Col>

                        {settingStore.getSetting().showInsurance &&
                            <Col sm={12} lg={6} md={6} className="card_layout">
                                <div>
                                    <HomeInsurance user={currentUser} />
                                </div>
                            </Col>
                        }
                    </Row>
                </div>
            </div>

            {settingStore.getSetting().showAppointments &&
                <Row>
                    <Col sm="12" className="pl-0 pr-0">
                        <HomeAppointments user={currentUser} />
                    </Col>
                </Row>
            }
             </>}
        </div>
    </>)
});
export default Home;