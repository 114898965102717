import { ActivityStore } from "./activityStore";
import { AlertMessageStore } from "./alertMessageStore";
import { AppointmentStore } from "./appointmentStore";
import { ClientStore } from "./clientStore";
import { LayoutStore } from "./layoutStore";
import { LibraryStore } from "./libraryStore";
import { PasswordStore } from "./passwordStore";
import { PaymentStore } from "./paymentStore";
import { PickListStore } from "./pickListStore";
import { SettingStore } from "./settingStore";
import { SpecialtyStore } from "./specialtyStore";
import { ConfirmStore } from "./confirmStore";
import { GuidelineStore } from "./guidelineStore";
import { PractitionerStore } from "./practitionerStore";
import { BasicDetailsStore } from "./basicDetailsStore";
import { SurveyStore } from "./surveyStore";

export const stores = {
    alertMessageStore: new AlertMessageStore(),
    layoutStore: new LayoutStore(),
    settingStore: new SettingStore(),
    appointmentStore: new AppointmentStore(),
    activityStore: new ActivityStore(),
    clientStore: new ClientStore(),
    pickListStore: new PickListStore(),
    libraryStore: new LibraryStore(),
    paymentStore: new PaymentStore(),
    passwordStore: new PasswordStore(),
    specialtyStore: new SpecialtyStore(),
    confirmStore: new ConfirmStore(),
    guidelineStore:new GuidelineStore(),
    practitionerStore:new PractitionerStore(),
    basicDetailsStore:new BasicDetailsStore(),
    surveyStore:new SurveyStore()
}