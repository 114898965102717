import React, { FC } from 'react';
import { Button, Card, CardTitle, CardImg } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { ClientUser } from '../../models/User';
import { useClientStore, useSettingStore } from '../../hooks/hooks';
import profile_information from '../../assets/images/profile_information_icon.png';

interface Props {
    user: ClientUser;
}

export const HomeInformation: FC<Props> = ({ user }) => {
    const history = useHistory();
    const settingStore = useSettingStore();
    const clientStore = useClientStore();
    const updateBasicsSettings = settingStore.getSetting().canSaveBasics;
    const updateDetailsSettings = settingStore.getSetting().canSaveDetails;

    const gotoBasicInfo = () => {
        clientStore.setSelectedTab(0);
        history.push('/profile');
    }

    const gotoDetailsInfo = () => {
        let idx = 1;
        if (!settingStore.getSetting().showBasics) idx -= 1;
        clientStore.setSelectedTab(idx);

        history.push('/profile')
    }

    const gotoContactInfo = () => {
        let idx = 2;
        if (!settingStore.getSetting().showBasics) idx -= 1;
        if (!settingStore.getSetting().showDetails) idx -= 1;
        clientStore.setSelectedTab(idx);

        history.push('/profile')
    }

    const gotoMessagingPref = () => {
        let idx = 4;
        if (!settingStore.getSetting().showBasics) idx -= 1;
        if (!settingStore.getSetting().showDetails) idx -= 1;
        if (!settingStore.getSetting().showInsurance) idx -= 1;
        clientStore.setSelectedTab(idx);

        history.push('/profile')
    }

    const getBasicButtonText = () => {
        if (updateBasicsSettings) {
            return "Update Basics";
        } else {
            return "View Basics";
        }
    }
    const getDetailButtonText = () => {
        if (updateDetailsSettings) {
            return "Update Details";
        } else {
            return "View Details";
        }
    }


    return (
        <div className="sections">
            <Card className='custom_card'>
                <div className="card_heading">
                    <CardImg className='icons' src={profile_information} ></CardImg>
                    <CardTitle tag="h5">Update Your Information</CardTitle>
                </div>
                <div className="status">
                    <CardTitle tag='p' >Profile Information.</CardTitle>
                </div>
                <div className="instructions">
                    {settingStore.getSetting().showBasics &&
                        <p><i>Basic</i> information is where you enter your name, address, phone numbers, email address. </p>
                    }
                    {settingStore.getSetting().showDetails &&
                        <p><i>Detail</i> information for race, languages and life statuses.</p>
                    }
                    <p><i>Contact</i> information for Emergency, Legal Guardian and Responsible Party.</p>
                </div>
                <div className='menu_buttons'>
                    {settingStore.getSetting().showBasics &&
                        <Button size="sm" onClick={gotoBasicInfo}>{getBasicButtonText()}</Button>
                    }
                    {settingStore.getSetting().showDetails &&
                        <Button size="sm" onClick={gotoDetailsInfo}>{getDetailButtonText()}</Button>
                    }
                    <Button size="sm" onClick={gotoContactInfo}>Update Contacts</Button>
                    <Button  size="sm" onClick={gotoMessagingPref}> Messaging Preferences</Button>
                </div>
            </Card>
        </div>
    )

}
export default HomeInformation;