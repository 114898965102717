import { makeAutoObservable } from 'mobx'
import { NoticationTypes } from '../models/enums';

export class LayoutStore {

    showNotification: boolean = false;
    notificationMessage: string = "";
    notificationType: NoticationTypes = NoticationTypes.error;

    setShowNotification = (flag: boolean, type: NoticationTypes, message: string = "") => {
        this.notificationType = type;
        this.showNotification = flag;
        this.notificationMessage = message;

        if (flag) {
            setTimeout(()=>{
                this.hideNotification()
            },6000);
        }
    };

    hideNotification = () => {
        this.showNotification = false;
    };

    constructor() {
        makeAutoObservable(this)
    };
}
