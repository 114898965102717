import { makeAutoObservable } from 'mobx'
import { SpecialtyResponse } from '../models/Specialty'

export class SpecialtyStore {

    specialties: SpecialtyResponse = {
        userToken: '',
        customerCare: '',
        confirmation: '',
        termsAndConditions: '',
        existingClient: '',
        specialties: [],
        allowScheduling: false,
        success: false,
        friendlyMessage: '',
    };

    setSpecialties = (specialties: SpecialtyResponse) => {
        this.specialties = specialties;
    };

    clientId : number  = 0;
    userToken : string = ''

    setSpecialtyResponse = (clientId : number,userToken : string) => {
        this.clientId = clientId;
        this.userToken = userToken;
    }

    checkedItems : number [] = [];
    selectedSpecialties : string [] = [];

    setCheckedItems = ( checkedItems : number[]) => {
        this.checkedItems = checkedItems;
    }

    setSelectedSpecialties = ( selectedSpecialties : string[]) => {
        this.selectedSpecialties = selectedSpecialties;
    }

    constructor() {
        makeAutoObservable(this)
    };
}