import { FC } from 'react';
import { Spinner } from 'reactstrap';
import { Fade } from '@progress/kendo-react-animation';

interface Props {
    show: boolean;
}

export const LoadingSpinner: FC<Props> = ({ show }) => {
    return (
        <>
        { show &&
            <Fade enter={true} exit={true}>
                <div className="text-center mt-2">
                    <Spinner color="primary"/>
                </div>
            </Fade>
        }
        </>
    )
}

