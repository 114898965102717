import { makeAutoObservable } from 'mobx'
import { SelectList } from '../models/ClientBasics'
import { PickListType } from '../models/enums';

export class PickListStore {

    disabilityList: SelectList[] = [];
    employmentStatusList: SelectList[] = [];
    ethnicityList: SelectList[] = [];
    genderList: SelectList[] = [];
    gradeList: SelectList[] = [];
    relationshipList: SelectList[] = [];
    languageList: SelectList[] = [];
    livingArrangementList: SelectList[] = [];
    maritalStatusList: SelectList[] = [];
    raceList: SelectList[] = [];
    residenceList: SelectList[] = [];
    schoolList: SelectList[] = [];
    smokingStatusList: SelectList[] = [];
    targetPopulationList: SelectList[] = [];
    insuranceCoList: SelectList[] = [];

    getPickList = (listType: PickListType): SelectList[] => {
        switch (listType) {
            case PickListType.Disability: {
                return this.disabilityList;
            }
            case PickListType.EmploymentStatus: {
                return this.employmentStatusList;
            }
            case PickListType.Ethnicity: {
                return this.ethnicityList;
            }
            case PickListType.Gender: {
                return this.genderList;
            }
            case PickListType.Grade: {
                return this.gradeList;
            }
            case PickListType.Relationship: {
                return this.relationshipList;
            }
            case PickListType.Language: {
                return this.languageList;
            }
            case PickListType.LivingArrangement: {
                return this.livingArrangementList;
            }
            case PickListType.MaritalStatus: {
                return this.maritalStatusList;
            }
            case PickListType.Race: {
                return this.raceList;
            }
            case PickListType.Residence: {
                return this.residenceList;
            }
            case PickListType.School: {
                return this.schoolList;
            }
            case PickListType.SmokingStatus: {
                return this.smokingStatusList;
            }
            case PickListType.TargetPopulation: {
                return this.targetPopulationList;
            }
            case PickListType.InsuranceCo: {
                return this.insuranceCoList;
            }
        }
        return [];
    }
    setPickList = (listType: PickListType, list: SelectList[]) => {
        switch (listType) {
            case PickListType.Disability: {
                this.disabilityList = list;
                break;
            }
            case PickListType.EmploymentStatus: {
                this.employmentStatusList = list;
                break;
            }
            case PickListType.Ethnicity: {
                this.ethnicityList = list;
                break;
            }
            case PickListType.Gender: {
                this.genderList = list;
                break;
            }
            case PickListType.Grade: {
                this.gradeList = list;
                break;
            }
            case PickListType.Relationship: {
                this.relationshipList = list;
                break;
            }
            case PickListType.Language: {
                this.languageList = list;
                break;
            }
            case PickListType.LivingArrangement: {
                this.livingArrangementList = list;
                break;
            }
            case PickListType.MaritalStatus: {
                this.maritalStatusList = list;
                break;
            }
            case PickListType.Race: {
                this.raceList = list;
                break;
            }
            case PickListType.Residence: {
                this.residenceList = list;
                break;
            }
            case PickListType.School: {
                this.schoolList = list;
                break;
            }
            case PickListType.SmokingStatus: {
                this.smokingStatusList = list;
                break;
            }
            case PickListType.TargetPopulation: {
                this.targetPopulationList = list;
                break;
            }
            case PickListType.InsuranceCo: {
                this.insuranceCoList = list;
                break;
            }
        }
    }

    constructor() {
        makeAutoObservable(this)
    }
}
