
const emailRegex = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);
const phoneRegex = new RegExp(/^[0-9 x()+-]+$/);
const ssnRegex = new RegExp(/^(\d{3}-?\d{2}-?\d{4}|XXX-XX-XXXX)$/);
//const passwordRegex = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/);

export const FormRequiredValidator = (value: string) => value ? '' : 'This field is required';
export const FormEmailValidator = (value: string) => value ? (emailRegex.test(value) ? '' : 'You have entered an invalid email address!') : '';

export const FormRequiredEmailValidator = (value: string) => {
    if (!value) return 'This field is required';
    return value ? (emailRegex.test(value) ? '' : 'You have entered an invalid email address!') : '';
}

//export const FormPasswordValidator = (value: string) => value ? (passwordRegex.test(value) ? '' : 'Password must contain at least 8 characters, 1 capital letter, 1 small letter, 1 number.') : '';
export const FormPhoneValidator = (value: string) => {
    if (!value || value === "" || value === "(___) ___-____" || value === "(___) ___-____ x____") return '';
    return value && phoneRegex.test(value) ? '' : 'Please enter a valid phone number.';
};
export const FormSSNValidator = (value: string) => {
    if (!value || value === "" || value === "___-__-____") return '';
    return value && ssnRegex.test(value) ? '' : 'Please enter a valid SSN.';
};