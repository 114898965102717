import { observer } from 'mobx-react';
import { Col, Row} from 'reactstrap';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from '@progress/kendo-react-buttons';
import { FormRequiredValidator  } from '../shared/FormFieldComponents';
import { FormInput } from '../shared/KendoFormComponents';
import { Dialog } from "@progress/kendo-react-dialogs";
import { useClientStore, usePasswordStore, useSettingStore } from '../../hooks/hooks';
import { passwordService } from '../../services';
import { LoadingSpinner } from '../shared/LoadingSpinner';
import { FC, useEffect } from 'react';

interface Props {
    currentPassword?: string;
}

export const ChangePassword: FC<Props> = observer(({currentPassword}) => {
    const passwordStore = usePasswordStore();
    const clientStore = useClientStore();
    const settingStore = useSettingStore();

    const pwdRegEx = settingStore.getSetting().passwordRegEx ? settingStore.getSetting().passwordRegEx : '(?=.*\\d)(?=.*[~!@#$%^*()_+]).{8,40}';
    const pwdRegExDesc = settingStore.getSetting().passwordRegExDesc ? settingStore.getSetting().passwordRegExDesc : 'Password must be at least 8 characters long including one special character and one digit';

    const passwordRegex = new RegExp(pwdRegEx);
    const FormPasswordValidator = (value: string) => value ? (passwordRegex.test(value) ? '' : pwdRegExDesc) : '';

    let title = "Change Password";

    if (currentPassword) {
        passwordStore.setChangeRequest({current: currentPassword, newPassword: ""});
        title = "Change Password Required";
    }

    //this is here to make sure current login
    useEffect(() => {
        sessionStorage.setItem("password-open", "1");
        passwordService.setStores(passwordStore, clientStore);
        (async () => await passwordService.checkLogin())();
    }, [passwordStore, clientStore]);

    const closePassword = () => {
        clientStore.setShowPasswordDialog(false);
        sessionStorage.setItem("password-open", "");
    };

    const changePassword = async (data: any, event: any) => {
        event.preventDefault();
        await passwordService.savePasswordChange(data);
    };

    return (
        <Dialog className='change-password ' title={title} onClose={() => closePassword()} width={350} autoFocus={true} closeIcon={currentPassword ? false : true}>
            <LoadingSpinner show={passwordStore.showSpinner}/>
            <Form
                onSubmit = {changePassword}
                initialValues={passwordStore.changeRequest}
                render = {(formProps: any) => (
                    <FormElement>
                        <Row>
                            <Col sm="12">
                                <Field label = "Current Password:" name = "current" id = "current" type = "password"
                                    component = {FormInput} validator={FormRequiredValidator} />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <Field label = "New Password:" name = "newPassword" id = "newPassword" type = "password"
                                    component = {FormInput} validator={FormPasswordValidator}
                                    hint={pwdRegExDesc} />
                            </Col>
                        </Row>
                        <div className="change-pw-buttons" >
                            { !currentPassword &&
                                <Button onClick={closePassword}
                                    themeColor={"base"}>
                                    Cancel
                                </Button>
                            }
                            <Button type="submit"
                                themeColor={"primary"}
                                disabled={!formProps.allowSubmit}>
                                Change Password
                            </Button>
                        </div>
                    </FormElement>
                )}/>
            { passwordStore.triedToChange && !passwordStore.changeResponseSuccess &&
                <div className='m-3 red1'>{passwordStore.changeResponseMessage}</div>
            }
        </Dialog>
    )
});
export default ChangePassword;