import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Webcam from "react-webcam";
import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from "@progress/kendo-react-dialogs";
import { useClientStore } from '../../hooks/hooks';
import { clientService } from '../../services';
import { authenticationService } from '../../services';
import { Upload, UploadHttpHeaders, UploadOnStatusChangeEvent } from '@progress/kendo-react-upload';
import appSettings from '../../AppSettings.json';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

export const AvatarDialog = observer(() => {
    const clientStore = useClientStore();
    const appInsights = useAppInsightsContext();

    const [imageSource, setImageSource] = useState("");
    const [hasVideo, setHasVideo] = useState(false);
    const [badCapture, setBadCapture] = useState(false);

    const authHeader: UploadHttpHeaders = {
        'authorization' :`bearer ${authenticationService.currentUserValue.token}`,
        'phostdomain' : window.location.hostname
        };

    const onStatusChange = (event: UploadOnStatusChangeEvent) => {
        const file = event.affectedFiles[0]
        if (file.status === 4 && event.response?.status === 200) {
            const thm = event.response.response as any;

            clientStore.setAvatarImage(thm.avatarBase64)
            let currentUser = authenticationService.currentUserValue;
            currentUser.profile.avatar = thm.avatarBase64;
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
            clientStore.setShowAvatarDialog(false);
        } else if (file.status === 0) {
            setBadCapture(true);
        }
    };

    const handleDevices = useCallback( (mediaDevices: any)  => {
            if (mediaDevices.filter((dev: any) => dev.kind === 'videoinput').length > 0) {
                setHasVideo(true);
            }
        }, [setHasVideo]);

    useEffect(() => {
        appInsights.trackPageView({ name: "Update-Avatar"});

        navigator.mediaDevices.enumerateDevices().then(handleDevices);
    }, [appInsights, handleDevices]);

    const videoConstraints = {
        width: 300,
        height: 240,
        facingMode: "user"
    };

    const closeAvatar =  () => {
        clientStore.setShowAvatarDialog(false);
    }

    const saveAvatar = async () => {
        setBadCapture(false);
        const success = await clientService.saveAvatar(clientStore, imageSource);

        if (!success){
            setBadCapture(true);
        } else {
            let currentUser = authenticationService.currentUserValue;
            currentUser.profile.avatar = clientStore.avatarImage;
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
            clientStore.setShowAvatarDialog(false);
        }
    }

    return (
        <Dialog className='change-password' title="Update Avatar" width={360} autoFocus={true} onClose={closeAvatar}>
            { !hasVideo &&
                <div className='label-center'>No Video camera detected</div>
            }
            { !imageSource && hasVideo && <>
                <div className='webcam-container'>
                    <Webcam className='webcam'
                        audio={false}
                        mirrored={true}
                        imageSmoothing={true}
                        height={240}
                        screenshotFormat="image/jpeg"
                        width={300}
                        videoConstraints={videoConstraints}
                        onUserMediaError={(e: any) => alert(e.message)}
                        >
                        {({ getScreenshot }) => (
                            <Button themeColor={"primary"}
                            onClick={() => {
                                const imageSrc = getScreenshot();
                                if (imageSrc !== null){
                                    setImageSource(imageSrc);
                                }
                                setBadCapture(false);
                            }}
                            >
                            Capture Avatar
                            </Button>
                        )}
                    </Webcam>
                </div>
                <div className='mt-2'>
                    <h6>Upload Image</h6>
                    <div className='small-text'>Max. Size 1M, types heic, jpg and png only</div>
                    <Upload
                        autoUpload={false}
                        batch={false}
                        multiple={false}
                        defaultFiles={[]}
                        withCredentials={true}
                        saveHeaders = {authHeader}
                        restrictions={{
                            allowedExtensions: [".hiec", ".jpg", ".jpeg", ".png"],
                            maxFileSize: 1000000,
                            minFileSize: 1000
                        }}
                        onStatusChange={onStatusChange}
                        saveUrl={`${appSettings.api_url}profile/uploadAvatar`}
                        >
                    </Upload>
                </div>
                </>
            }
            { imageSource &&
                <div className='label-center'>
                    <img src= {imageSource} alt='' className='webcam'/>
                    <div className='mt-2'>
                        <Button className='mr-2' onClick={() => {setImageSource(''); setBadCapture(false);}}>ReCapture</Button>
                        <Button themeColor={"primary"} onClick={saveAvatar}>Save Avatar</Button>
                    </div>
                </div>
            }
            { badCapture &&
                <div className='mt-2 label-center'>
                    <h6>Oops! We need a better image for Avatar capture. Please ensure your photo is clear, well-lit, and your face is fully visible.</h6>
                </div>
            }
        </Dialog>
    );

});

export default AvatarDialog;