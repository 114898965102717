import { ProviderSetting } from '../models/ProviderSetting';
import { SettingStore } from '../stores/settingStore';
import { http } from './http';
import appSettings from '../AppSettings.json';
import { ClientUser } from '../models/User';

const localUser = localStorage.getItem('currentUser');
const jsonUser: ClientUser = localUser ? JSON.parse(localUser) : null;

const fetchSettings = async (store: SettingStore) => {
    const response = await http<undefined, ProviderSetting>({
        path: "setting/all"
    });
    if (response.ok && response.parsedBody) {
        store.setSettings(response.parsedBody);
        store.setMessageCount(jsonUser.profile.alertCount);
        store.setSettingsLoaded();
    }

    return response.ok;
}

const fetchLogo = async (store: SettingStore, maxHeight: number) => {
    if (store.logoLoaded) {
        return true;
    }

    const path = `setting/getLogo?hostname=${window.location.hostname}&height=${maxHeight}`;
    const request = new Request(`${appSettings.api_url}${path}`, {
        method: 'get',
        headers: {'Content-Type': 'application/text'},
    });

    fetch(request)
        .then(res => { return res.text() })
        .then(data => {
            if (data) {
                store.setLogo(data);
            }
            store.setLogoLoaded();
        });

    return true;
}

export const providerSettingService = {
    fetchSettings,
    fetchLogo
}