import { makeAutoObservable } from 'mobx'
import { Appointments } from '../models/Appointments';
import { SaveAppointmentStatus } from '../models/enums';

export class AppointmentStore {

    appointments: Appointments = {
        success: false,
        friendlyMessage: '',
        appointments: [],
        total: 0
    };

    allAppointments: Appointments = {
        success: false,
        friendlyMessage: '',
        appointments: [],
        total: 0
    };

    apptSaveStatus: SaveAppointmentStatus = 0;

    showAppointmentSpinner: boolean = false;
    addAppointmentMessage: string = '';

    setAppointments = (apts: Appointments) => {
        this.appointments = apts;
    };

    setAllAppointments = (apts: Appointments) => {
        if (!apts.success || this.allAppointments.appointments.length === 0) {
            this.allAppointments = apts;
        } else {
            this.allAppointments.appointments = this.allAppointments.appointments.concat(apts.appointments);
        }

    };

    setShowAppointmentSpinner = (flag: boolean) => {
        this.showAppointmentSpinner = flag;
    };

    setAddAppointmentMessage = (msg: string) => {
        this.addAppointmentMessage = msg;
    }

    setApptSaveStatus = (status: SaveAppointmentStatus) => {
        this.apptSaveStatus = status;
    }


    constructor() {
        makeAutoObservable(this)
    }
}
