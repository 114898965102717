import { FC, useEffect } from 'react';
import { CardImage, Card, CardBody } from '@progress/kendo-react-layout';
import bag from '../../../assets/images/Bag.png';
import doctor from '../../../assets/images/Doctor.png';
import location from '../../../assets/images/location.png';
import { useAppointmentStore, usePractitionerStore } from '../../../hooks/hooks';
import { observer } from 'mobx-react';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { PractitionerData } from '../../../models/Practitioner';
import { appointmentService } from '../../../services';
import { Button } from '@progress/kendo-react-buttons';
import { CardFooter } from 'reactstrap';
import { Dialog } from "@progress/kendo-react-dialogs";
import React from 'react';

interface Props {
    practitioner: PractitionerData;
}

const Practitioner: FC<Props> = observer(({ practitioner }) => {
    const practitionerStore = usePractitionerStore();
    const appointmentStore = useAppointmentStore();
    const [visible, setVisible] = React.useState<boolean>(false);
    var description = `${practitioner.description == null ? "" : practitioner.description}`;

    useEffect(() => {
        document.body.style.overflow = visible ? "hidden" : "scroll";
    }, [visible]);

    const toggleDialog = () => {
        setVisible(!visible);
    };

    useEffect(() => {
        (async () => await appointmentService.fetchUserImage(practitionerStore, practitioner))();
    }, [practitioner, practitionerStore]);

    const addressClik = () => {
        window.open(`https://www.google.com/maps/place/${encodeURIComponent(practitioner.address)}`, '_blank');
    }

    const bookAppointment = async () => {
        appointmentStore.setAddAppointmentMessage("");
        practitionerStore.setSelectedPractitioner(practitioner);
        await appointmentService.fetchUserBillingCodes(practitionerStore, practitioner.id);
    }

    return (
        <div>
            <Tooltip anchorElement="target" showCallout={true} openDelay={800}>
                <Card orientation="vertical" className="practitioner-card-section">
                    <span className='doctordefaultimg'>
                        <CardImage src={practitioner.userImage ? practitioner.userImage : '/images/doctor2.png'} />

                        <div className="doctor-basic-info">
                            <div className='doctorcontent'>{practitioner.doctorName}</div>
                            <div className='description'>{practitioner.education}</div>
                        </div>
                    </span>
                    <CardBody>
                        <Tooltip anchorElement="target" position="top" showCallout={true} openDelay={800} className = "practitioner-Tooltip">
                            <div>
                                <p className="descriptions mb-0" id="descriptions">{description}</p>
                                {description.length !== 0 && <span onClick={toggleDialog} className="read-more">Read more</span>}
                                {practitioner.experience &&
                                    <div className='experience mt-3'>
                                        <img src={bag} className='mr-2' alt='bag'></img>
                                        <span>{practitioner.experience} Years of Experience</span>
                                    </div>
                                }
                                {(practitioner.specialization !== "" && practitioner.specialization != null) &&
                                    <div className='speciality mt-3' >
                                        <img src={doctor} className='mr-2 mt-1 schedulericons' alt='doctor'></img>
                                        <span className='specializetext'>Specializes in {practitioner.specialization}</span>
                                    </div>
                                }
                                {(practitioner.hospitalName !== "" && practitioner.hospitalName != null) &&
                                    <div className='speciality mt-3'>
                                        <img src={location} className='mr-2 schedulericons' alt='location'></img>
                                        <span className="location-hospital">{practitioner.hospitalName}</span>
                                    </div>
                                }

                                {(practitioner.address !== "" && practitioner.address != null) &&
                                    <div className='location ml-3 mt-2' title="View in Google Maps" onClick={addressClik}><span>{practitioner.address}</span></div>
                                }
                            </div>
                        </Tooltip>
                    </CardBody>
                    {practitioner.hasExistingAppt === 1 &&
                        <div className='existing-apt text-center'>You already have an appointment with this provider, please return after your next scheduled appointment to arrange a follow-up.</div>
                    }
                    {practitioner.hasExistingAppt !== 1 && <CardFooter className="practitioner-footer">
                        {practitioner.hasExistingAppt === 0 &&
                            <div className='appointment-buttons'>
                                {(!practitionerStore.selectedPractitioner || practitionerStore.selectedPractitioner.id !== practitioner.id) &&
                                    <Button className="book-button" size={'small'}
                                        onClick={bookAppointment}>
                                        Book an Appointment
                                    </Button>
                                }
                                {(practitionerStore.practitioners.length > 1 && practitionerStore.selectedPractitioner && practitionerStore.selectedPractitioner.id === practitioner.id) &&
                                    <Button themeColor={"secondary"} size={'small'}
                                        onClick={() => {
                                            practitionerStore.setClearSelectedPractitioner()
                                        }
                                        }>
                                        Change Provider
                                    </Button>
                                }
                            </div>
                        }
                    </CardFooter>
                    }
                    {visible && (
                        <Dialog
                            title={practitioner.doctorName}
                            onClose={toggleDialog}
                            className="dialog-popup">
                            {practitioner.description !== null && practitioner.description !== "" && <p>{practitioner.description == null ? "" : practitioner.description}</p>}
                        </Dialog>
                    )}
                </Card>
            </Tooltip>
        </div>
    )
});

export default Practitioner;