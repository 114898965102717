import { makeAutoObservable } from 'mobx'
import { Guideline } from '../models/Guideline'

export class GuidelineStore {
    
    guideline: Guideline = {
        reviewGuideline:"Your application will be reviewed by our front desk staff.",  
        sendMessageGuideline : "We will send a message about your application to your registered email ID.",
        registerMobileNumberGuideline : "We will call your registered mobile number within 24 hours."
    };

    constructor() {
        makeAutoObservable(this)
    };
}