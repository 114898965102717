import React, {FC, useEffect} from 'react';
import { RouteComponentProps} from 'react-router-dom';
import { authenticationService } from '../../services/authentication.service';

interface RouteParms {
    key: string;
}

export const SSOLogin: FC<RouteComponentProps<RouteParms>> = ({match}) => {

    useEffect(() => {
        try {
            (async () => await authenticationService.SSOLogin(match.params.key))();
        } catch (error) {
            window.location.href="/login"
        }
    });

    return (
        <>
        <h4 style={{margin: 20}}>Signing you into the Client Portal.....</h4>
        <h5 style={{marginLeft: 20}}>If you are not redirected in 10 seconds <a href='/login'>Click Here</a></h5>
        </>
    )
};

export default SSOLogin;