import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import '@progress/kendo-theme-bootstrap/dist/all.css';
import './App.scss';
import * as signalR from "@microsoft/signalr";
import appSettings from './AppSettings.json';
import Login from './components/account/Login';
import Home from './components/home/Home';
import { Layout } from './components/Layout';
import NotFound from './components/NotFound';
import { alertMessageService, authenticationService, providerSettingService } from './services';
import { useAlertMessageStore, useLayoutStore, useSettingStore } from './hooks/hooks';
import Forgot from './components/account/Forgot';
import SSOLogin from './components/account/SSOLogin';
import { useIdleTimer } from 'react-idle-timer';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from '@progress/kendo-react-buttons';
import { Spinner } from 'reactstrap';
import { ApptSchedulerLayoutRoute } from './components/ApptSchedulerLayout';
import BadConfig from './components/client/appointmentscheduler/BadConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';

const Activity = lazy(() => import('./components/activity/Activity'));
const Message = lazy(() => import('./components/client/message/Message'));
const ReceiptViewer = lazy(() => import('./components/payment/ReceiptViewer'));
const Client = lazy(() => import('./components/client/Client'));
const Payment = lazy(() => import('./components/payment/Payment'));
const Library = lazy(() => import('./components/client/library/Library'));
const DocumentViewer = lazy(() => import('./components/client/library/documents/DocumentViewer'));
const DocumentPDFViewer = lazy(() => import('./components/client/library/documents/DocumentPDFViewer'));
const DocumentImageViewer = lazy(() => import('./components/client/library/documents/DocumentImageViewer'));
const AllAppointments = lazy(() => import('./components/client/Appointment/AllAppointments'));

const Specialty = lazy(() => import('./components/client/appointmentscheduler/Specialty'));
const Survey = lazy(() => import('./components/client/appointmentscheduler/Survey'));
const BasicDetails = lazy(() => import('./components/client/appointmentscheduler/BasicDetails'));
const PractitionerList = lazy(() => import('./components/client/appointmentscheduler/PractitionerList'));
const Scheduler = lazy(() => import('./components/client/appointmentscheduler/Scheduler'));
const ExistingUser = lazy(() => import('./components/client/appointmentscheduler/ExistingUser'));
const Booking = lazy(() => import('./components/client/appointmentscheduler/Booking'));
const Confirm = lazy(() => import('./components/client/appointmentscheduler/Confirm'));
const CustomerCare = lazy(() => import('./components/client/appointmentscheduler/Customercare'));
const TermsAndConditions = lazy(() => import('./components/client/appointmentscheduler/TermsAndConditions'));

const CustomTitleBar = () => {
  return (
    <div className="timeout-title">
      <FontAwesomeIcon icon={faClock} size="lg" ></FontAwesomeIcon>
      <span className='pl-2'>Your session is about to time out!</span>
    </div>
  );
};

const App: React.FC =  () => {
  const settingStore = useSettingStore();
  const alertMessageStore = useAlertMessageStore();
  const layoutStore = useLayoutStore();
  const currentUser = authenticationService.currentUserValue;
  const [showIdle, setShowIdle ] = useState(false);
  const [paymentEnabled, setPaymentEnabled ] = useState(false);

  const onIdle = () => {
    if (currentUser && showIdle) {
      setShowIdle(false);
      authenticationService.logout();
    }
  }
  const onActive = (event: any) => {
    setShowIdle(false);
  }
  const onPrompt = () => {
    if (currentUser) {
      setShowIdle(true);
    }
  }

  const onBeforeUnload = useCallback(() => {
    localStorage.setItem("beforeunloadTS", new Date().toString());
  }, []);

  const onLoad = useCallback( () => {
    const ts = localStorage.getItem("beforeunloadTS")
    if (ts){
      localStorage.removeItem("beforeunloadTS");
      const diff = Math.abs(new Date(ts).valueOf() - new Date().valueOf());

      if (diff > 10000) {
        window.removeEventListener("beforeunload", onBeforeUnload);
        window.removeEventListener("load", onLoad);

        setTimeout(authenticationService.logout, 1000);
      }
    }
  }, [onBeforeUnload]);

  useIdleTimer({onIdle, onActive, onPrompt, timeout: 1000 * 60 * 18, promptBeforeIdle: 30000 });

  useEffect(() => {
    if (currentUser) {
      window.addEventListener("beforeunload", onBeforeUnload);
      window.addEventListener("load", onLoad);

      (async () => await providerSettingService.fetchSettings(settingStore).then(() => {
        setPaymentEnabled(!settingStore.getSetting().hidePayments);
      }))();

      const options = {
        accessTokenFactory: () => { return appSettings.signalRToken }
      }

      const hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(appSettings.signalRUri, options)
        .configureLogging(signalR.LogLevel.Error)
        .build();

      hubConnection.on("ReceiveMessage", (notification, message) => {
        if (notification === "ClientAlert") {
          settingStore.setMessageCount(message.clientAlertsCount);
          if (window.location.href.indexOf("messages") > 0) {
            (async () => await alertMessageService.fetchMessages(alertMessageStore, layoutStore))();
          }
          alertMessageService.updateStorageMessageCount(message.clientAlertsCount);
        }
      })

      const signalRStart = () => {
        hubConnection.start().then(() => {
          let provider = appSettings.dBOverride;
          if (appSettings.dBOverride === "") {
            const parts = currentUser.hostname.split('.');
            provider = `pro_PIMSY_${parts[0]}`;
          }

          var initNot = {
            app: 'CP',
            clientId: currentUser.clientId,
            groups: ['ClientAlert'],
            provider
          };
          hubConnection.invoke("InitNotifications", initNot);
        }).catch((err) => {
          setTimeout(signalRStart, 15000);
          return console.error(err.toString());
        });
      }

      signalRStart();
    }

    (async () => await providerSettingService.fetchLogo(settingStore, 54))();

  },[alertMessageStore, currentUser, layoutStore, onBeforeUnload, onLoad, settingStore]);

  if (!currentUser) {
    return (
      <BrowserRouter>
        <div className="appscheduler-root">
          <Suspense fallback={<div className="text-center mt-2"><Spinner color="primary"/></div>}>
            <Switch>
              <ApptSchedulerLayoutRoute path="/specialty" component={Specialty} className="specialtycommon"/>
              <ApptSchedulerLayoutRoute path="/survey" component={Survey} className="survey"/>
              <ApptSchedulerLayoutRoute path="/basic-details" component={BasicDetails} className="basicdetails"/>
              <ApptSchedulerLayoutRoute path="/practitioner-list" component={PractitionerList} className="Practitioner-list" title="Available Practitioners"/>
              <ApptSchedulerLayoutRoute path="/scheduler" component={Scheduler} className="appointmentscheduler" title="Select Your Date & Time Slot"/>
              <ApptSchedulerLayoutRoute path="/existinguser" component={ExistingUser} className="existinguser" />
              <ApptSchedulerLayoutRoute path="/booking" component={Booking} className="booking"/>
              <ApptSchedulerLayoutRoute path="/confirm" component={Confirm} className="confirm" title="Review Your Appointment"/>
              <ApptSchedulerLayoutRoute path="/customercare" component={CustomerCare} className="customercare"/>
              <ApptSchedulerLayoutRoute path="/termsandconditions" component={TermsAndConditions} className="termsandconditions"/>
              <ApptSchedulerLayoutRoute path="/badconfig" component={BadConfig} className="termsandconditions"/>
              <Layout user={currentUser}>
                <Redirect from="/" to="/login" />
                <Route path="/sso/:key" component={SSOLogin}/>
                <Route path="/login" component={Login}/>
                <Route path="/forgot" component={Forgot}/>
                <Route path="/forms/:id?" component={Login}/>
              </Layout>
            </Switch>
          </Suspense>
        </div>
      </BrowserRouter>
    )
  }

  return (<>
    { showIdle &&
      <Dialog title={<CustomTitleBar/>} closeIcon={false}>
        <div style={{ margin: "25px", textAlign: "center", fontWeight: 600 }}>
          Are you still there?
        </div>
        <DialogActionsBar>
          <Button type="button" themeColor={"primary"} onClick={() => setShowIdle(false)}>Yes</Button>
        </DialogActionsBar>
      </Dialog>
    }

    <BrowserRouter>
      <Redirect from="/home" to="/" />
      <div className="root">
        <Layout user={currentUser}>
          <Suspense fallback={<div className="text-center mt-2"><Spinner color="primary"/></div>}>
            <Switch>
              <Route path="/forms/:id?" component={Home}/>
              <Route path="/login" component={Login}/>
              <Route path="/forgot" component={Forgot}/>
              <Route exact path="/" component={Home} />
              <Route path="/messages" component={Message}/>
              <Route path="/profile" component={Client}/>

              { paymentEnabled &&
                <Route path="/payment" component={Payment}/>
              }

              <Route path="/activity" component={Activity}/>
              <Route path="/library" component={Library}/>
              <Route path="/document-viewer" component={DocumentViewer}/>
              <Route path="/document-pdf-viewer" component={DocumentPDFViewer}/>
              <Route path="/document-image-viewer" component={DocumentImageViewer}/>

              { paymentEnabled &&
                <Route path="/receipt-viewer" component={ReceiptViewer}/>
              }

              <Route path="/allappointments" component={AllAppointments}/>
              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </Layout>
      </div>
    </BrowserRouter>
    </>);
};

export default App;


