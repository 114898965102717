import React, { FC } from 'react';
import { Button, Card, CardTitle, CardImg } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { ClientUser } from '../../models/User';
import { useLibraryStore } from '../../hooks/hooks';
import forms_icon from '../../assets/images/forms_icon.png';
interface Props {
    user: ClientUser;
}

export const HomeForms: FC<Props> = ({ user }) => {
    const history = useHistory();
    const libraryStore = useLibraryStore();

    const gotoForms = () => {
        libraryStore.setSelectedTab(0);
        history.push('/library');
    }

    const gotoDocuments = () => {
        libraryStore.setSelectedTab(1);
        history.push('/library');
    }

    const gotoUploadDocuments = () => {
        libraryStore.setSelectedTab(2);
        history.push('/library');
    }

    const haveFormsToComplete = true; //replace with data value

    return (
        <div className="sections">
            <Card className='custom_card'>
                <div className="card_heading">
                    <CardImg className='icons' src={forms_icon} ></CardImg>
                    <CardTitle tag="h5">Complete Forms</CardTitle>
                </div>
                <div className="status">

                    <CardTitle tag="p">
                        {haveFormsToComplete &&
                            <>There are forms available.</>
                        }
                        {!haveFormsToComplete &&
                            <>You have completed all forms.</>
                        }

                    </CardTitle>

                </div>

                <div className="instructions">
                    {haveFormsToComplete &&
                        <p><i>Forms</i> are provided by your provider for further details about you that need to be completed.</p>
                    }
                    {!haveFormsToComplete &&
                        <p><i>Forms</i> are provided by your provider to complete for further details about you.</p>
                    }
                    <i> Documents</i> are provided for you to review and possibly add a signature.
                </div>
                <div className='menu_buttons'>
                    <Button size="sm" onClick={gotoForms}> Complete Forms</Button>
                    <Button size="sm" onClick={gotoDocuments}> View Documents</Button>
                    <Button size="sm" onClick={gotoUploadDocuments}> Upload Documents</Button>
                </div>
            </Card>
        </div>
    )

}
export default HomeForms;