
export const dateToLocal = (utcDate: string): string => {
    if (!utcDate){
        return "";
    }

    if (!utcDate.includes("GMT") &&!utcDate.endsWith('Z')) {
        utcDate = utcDate + 'Z';
    }
    let utcdate = new Date(utcDate);
    return utcdate.toLocaleTimeString('en-US', {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: '2-digit'});
}

export const dateToLocalDate = (utcDate: string): string => {
    if (!utcDate){
        return "";
    }

    if (!utcDate.includes("GMT") && !utcDate.endsWith('Z')) {
        utcDate = utcDate + 'Z';
    }
    let utcdate = new Date(utcDate);
    return utcdate.toLocaleDateString('en-US', {year: 'numeric', month: 'numeric', day: 'numeric'});
}

export const dateToUTC = (localDate: Date): string => {
    if (!localDate){
        return "";
    }
    return localDate.toISOString();
}